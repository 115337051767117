.pagination {
	&__link {
		color: $dark-gray;
		background-color: $body-background;
		border: $global-border;
		padding: $global-spacing/4 $global-spacing/2;
		transition: $global-transition;

		@include mq(sm-down) {
			padding: $global-spacing/8 $global-spacing/3;
		}

		&:hover,
    &.current {
			border: 1px solid $primary-color;
			color: $primary-color;
		}
	}
}
