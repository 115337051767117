/* =========================================================================
*
* Blockquote
*
* ====================================================================== */

.quote-preview {
  text-align: right;

  &__icon {
    width: $global-spacing*2;
    margin-top: -$global-spacing/2;
    margin-left: -$global-spacing/2;
    text-align: left;

    ~ p {
      margin-top: -$global-spacing/4;
      margin-bottom: $global-spacing/4;
    }
  }

  blockquote {
    padding-left: $global-spacing*.75;
    font-weight: $font-weight-medium;
    line-height: 1.4;
    border-left: 3px solid $light-gray;
    text-align: left;
  }

  .link {
    display: inline-flex;
  }
}