.gallery-container {
	display: inline-block;
	width: 100%;

	a {
		position: relative;
		display: block;
		padding-top: percentage(2/3);
		height: 0;
		width: 100%;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: block;
    object-fit: cover;
    font-family: 'object-fit:cover';
	}

	.gallery-big {
		position: relative;

		@include mq(sm) {
			float: left;
			width: 66.5%;
			margin-right: 1.5%;
		}
	}

	.gallery-thumbs {
		position: relative;
		display: flex;
		margin-top: $global-spacing/4;

		@include mq(sm) {
			flex-direction: column;
			width: 32%;
			margin-top: 0;
			float: left;
		}
	}

	.gallery-thumbs a {
		position: relative;

		@include mq(sm-down) {
			padding-top: percentage(1/3);
		}

		&:first-child {
			@include mq(sm) {
				margin-bottom: 5.25%;
			}
			@include mq(sm-down) {
				margin-right: $global-spacing/4;
			}
		}

		&:nth-child(n+2) {
			&:after {
				content: attr(data-content);
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				color: white;
				font-size: $medium-heading-size;
				font-weight: $font-weight-bold;
				background: transparentize(black, 0.25);

				@include mq(sm-down) {
					font-size: $font-base;
				}
			}

			&:nth-child(n+3) {
				display: none;
			}
		}
	}
}