.breadcrumbs {
	display: flex;
	align-items: center;
	margin-bottom: $global-spacing/2;

	li {
		display: flex;
		align-items: center;
		color: $gray;
		font-size: $font-base-small;
		font-weight: $font-weight-bold;
		line-height: 1;

		@include mq(sm-down) {
			font-size: $font-base-smallest;

			// Hide crumbs between first and last to fit on screen
			&:not(:first-child):not(:last-child) a {
				font-size: 0;

				&::before{
					content: '...';
					font-size: initial;
				}
			}

			&:last-child a {
				white-space: nowrap;
  			overflow: hidden;
  			text-overflow: ellipsis;
			}
		}

		@include mq(smx-down) {
			&:last-child a {
				max-width: 70vw;
			}
		}

		&:not(:last-child):after {
			content: '/';
			color: $light-gray;
			padding: 0 $global-spacing/8 0 $global-spacing/8;
		}

		i {
			display: inline-block;

			// Icon
			&::before {
				font-size: 18px; // Needs to be pixels for IE
			}
		}

		a {
			color: $gray;

			@include mq(sm-down) {
				font-weight: $font-weight-bold;
			}

			&:hover,
			&:focus {
				color: $dark-gray;
			}
		}
	}
}