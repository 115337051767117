%block-content {
  padding: $global-spacing / 2 $global-spacing / 2 $global-spacing;

  @include mq(sm) {
    padding: $global-spacing;
  }
}

.block {
  background-color: $white;
  border: $global-border;

  ~ .block {
    border-top: none;
  }

  &__content {
    @extend %block-content;

    > *:last-child {
      margin-bottom: 0;
    }

    // Om meerdere blokken samen te voegen
    ~ .block__content {
      h2,
      h3 {
        border-top: $global-border;
        padding-top: $global-spacing;
        margin-top: -$global-spacing;
      }
    }
  }

  .block__title {
    color: $body-text;
    margin-bottom: $global-spacing * 0.75;

    &--small {
      font-size: $font-base-large;
    }

    + .block__subtitle {
      margin-top: -$global-spacing/2;
    }

    @include mq(sm-down) {
      font-size: $font-base;

      &--small {
        font-size: $font-base-small;
      }

      + .block__subtitle {
        font-size: $font-base-small;
        margin-bottom: $global-spacing * 0.75;
      }
    }
  }

  .block__intro {
    margin: 0;
  }

  .block__footer {
    @extend %block-content;
    background: $body-background;
    padding-top: $global-spacing/2;
    padding-bottom: $global-spacing/2;
    border-top: $global-border;
  }

  &.animated {
    animation: slideIn 0.8s $global-cubic 2s forwards;
    visibility: hidden;
    opacity: 0;
  }
}

// style variant -- hightlighted
.block--highlighted {
  margin-bottom: $global-spacing;
  .title {
    color: $secondary-color;
    margin-bottom: 0;

    &:before {
      margin-right: $global-spacing/2;
    }
  }
}

@keyframes slideIn {
  from {
    transform: translate3d(0, 100px, 0);
    opacity: 0;
    visibility: hidden;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible;
  }
}
