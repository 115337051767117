// Receipt
.receipt {
  border-top: 4px solid $primary-color;

  // Create overlay
  @include mq(md-down) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: calc(100% - #{$fixed-footer-height});
    margin: 0;
    padding: 0;
    background: $white;
    transform: translateY(100vh);
    transition: transform 0.75s 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
		overflow-y: scroll;
    z-index: 100;

    &.is-active {
      transition: transform 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
      transform: translateY(0);
    }

    .block {
      border: 0;
    }

    // Hide total in overlay
    .receipt__total {
      display: none;
    }
  }
}

.receipt__price {
  display: flex;

  span:last-child {
    padding-left: $global-spacing/2;
    font-weight: $font-weight-bold;
  }
}

.receipt__total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -#{$global-spacing/2};
  padding: 1.4rem $global-spacing / 2;

  span {
    font-weight: 400;
  }

  @include mq(md-down) {
    background-color: $white;
    border-top: $global-border;
  }

  @include mq(lg) {
    .receipt__price {
      width: 100%;
      justify-content: space-between;
      align-items: center;

      // Price total
      span:last-child {
        font-size: 2.2rem;
      }
    }
  }
}

// Overview booking page
.receipt--overview {
  [class^="col"] {
    display: flex;
    @include mq(sm-down) {
      flex-wrap: wrap;
    }

    &:not(:last-of-type) {
      margin-bottom: $global-spacing;
    }

    dl {
      width: percentage(1/3);
      padding-right: $global-spacing/4;

      @include mq(sm-down) {
        width: percentage(1/2);
        margin-bottom: $global-spacing/2;
      }

      @include mq(smx-down) {
        width: 100%;
      }
    }
  }

  .receipt__total p {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: $global-spacing/2;
    font-size: $small-heading-size;
    font-weight: $font-weight-bold;

    @include mq(sm-down) {
      padding: $global-spacing/4;
      font-size: $font-base-large;
    }
  }
}

.receipt__meta {
  font-size: $font-base-small;

  @include mq(sm-down) {
    margin-bottom: $global-spacing/2;
    font-size: $font-base-smallest;
  }

  li {
    display: flex;
    align-items: flex-start;

    // Icon
    &::before {
      position: relative;
      top: 4px; // Visally better aligned

      @include mq(sm) {
        font-size: 20px; // Needs to be px for IE
      }
    }
  }

  p {
    margin-bottom: $global-spacing/2;
    padding-left: $global-spacing/2;
    line-height: 1.4;
  }

  [class^="meta-"] {
    span {
      display: block;
      font-weight: $font-weight-normal;

      @include mq(sm-down) {
        display: none;
      }
    }
  }
}

// Used for receipt
.receipt-list {
  li,
	li:last-child {
		border: 0;
	}

	.receipt-list__title {
		font-size: $font-base-small;
		margin-bottom: 0;
	}

	p {
		display: flex;
		font-size: $font-base-small;
		margin-bottom: $global-spacing/2;

		@include mq(sm-down) {
			font-size: 1.4rem;
		}

		span {
			margin-left: auto;
			padding-left: $global-spacing/4;
		}
  }

  // When placed in accordion
  .accordion-inner & {
    margin-top: $global-spacing/4;
  }

  // When placed in block footer
  .block__footer & {
    margin: 0;

    li:last-child p {
      margin-bottom: 0;
    }
  }
}

// Receipt fixed footer
.receipt__footer {
  @extend %fixed-footer;

  @include mq(lg) {
    display: none;
  }
}

.receipt__trigger {
	@include button-icon('r', before, 15px);
	&:before {
		transform: rotate(180deg);
	}
  &.active {
    // Icon
    &::before {
      transform: rotate(0deg);
    }
  }
}