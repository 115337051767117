/*!
/* Stylesheet voor Crusj, realisatie door Studio Drieluik
*/

@import "~normalize.css/normalize";
@import '~flatpickr/dist/flatpickr.css';
@import "~@fancyapps/ui/dist/fancybox.css";

/* Vendor */
// @import 'vendor/normalize';
@import 'vendor/wordpress';
// @import 'vendor/fancybox';

/* Base */
@import 'base/variables';
@import 'base/mixins';
@import 'base/breakpoints';
@import 'base/typography';
@import 'base/base';
@import 'base/icons';

/* Helpers */
@import 'helpers/helpers';

/* Layout */
@import 'layout/base';
@import 'layout/grid';

/* Components */
@import 'components/accordion';
@import 'components/alert';
@import 'components/animations';
@import 'components/block';
@import 'components/blockquote';
@import 'components/blogs';
@import 'components/booking-overview';
@import 'components/breadcrumbs';
@import 'components/buttons';
@import 'components/cards';
@import 'components/cookiebar';
@import 'components/datepicker';
@import 'components/editor-content';
@import 'components/filter';
@import 'components/gallery';
@import 'components/guarantees';
@import 'components/hero';
@import 'components/iframe';
@import 'components/label';
@import 'components/lightbox';
@import 'components/links';
@import 'components/lists';
@import 'components/maps';
@import 'components/navigation';
@import 'components/pagination';
@import 'components/payments';
@import 'components/price-card';
@import 'components/receipt';
@import 'components/reinsurance';
@import 'components/results';
@import 'components/review';
@import 'components/roadmap';
@import 'components/search';
@import 'components/slideshow';
@import 'components/social-media';
@import 'components/stepper';
@import 'components/sticky-nav';
@import 'components/tabs';
@import 'components/testimonials';
@import 'components/text';
@import 'components/tooltip';
@import 'components/trustpilot';
@import 'components/video';
@import 'components/widget';

/* Form */
@import 'components/form/input';
@import 'components/form/forms';
@import 'components/form/select';
@import 'components/search';

/* Layout */
@import 'layout/single-reizen';
@import 'layout/destinations';
@import 'layout/booking';
@import 'layout/homepage';
@import 'layout/page';
@import 'layout/aside';
@import 'layout/footer';

/* Shame */
@import 'shame';
