/* =========================================================================
*
* Helpers for spacing
* -- Creates classes for adding padding or margin
* -- Uses steps of 8 pixels
* -- (e.g. `u-mb-2` which means margin bottom: 1.6rem)
*
* ====================================================================== */

$sides: (
  "t": "top",
  "b": "bottom",
  "l": "left",
  "r": "right",
);
@for $i from 1 through 12 {
  @each $prefix, $value in $sides {
    @each $key, $breakpoint in $site-breakpoints {
      .u-m#{$prefix}-#{$i} {
        margin-#{$value}: #{($i * 8) / 10}rem;
      }

      .u-p#{$prefix}-#{$i} {
        padding-#{$value}: #{($i * 8) / 10}rem;
      }

      @include mq($key) {
        .u-m#{$prefix}-#{$i}\@#{$key} {
          margin-#{$value}: #{($i * 8) / 10}rem;
        }

        .u-p#{$prefix}-#{$i}\@#{$key}  {
          padding-#{$value}: #{($i * 8) / 10}rem;
        }
      }
    }
  }
}

/* =========================================================================
*
* Helpers for hiding
* -- Creates classes for hiding elements for specific breakpoints
* -- (e.g. `u-hidden@sm-down` which means hide below `sm-down` breakpoint)
*
* ====================================================================== */

@each $key, $value in $site-breakpoints {
  .u-visible\@#{$key} {
    display: none;
  }
  @include mq($key) {
    .u-visible\@#{$key} {
      display: block;
    }
    .u-hidden\@#{$key} {
      display: none!important;
    }
  }
}

// Helpers
.only-mobile {
  display: none !important;

  @include mq(sm-down) {
    display: block !important;

    &.inline-block {
      display: inline-block !important;
    }

    &.unset {
      display: unset !important;
    }
  }
}


.only-tablet {
  display: none;
  @include mq(sm) {
    display: block;
  }
}

.only-desktop {
  display: none;
  @include mq(lgx) {
    display: block;
  }
}

.hide-desktop {
  display: block;
  @include mq(lgx) {
    display: none;
  }
}

.hide-mobile {
  @include mq(sm-down) {
    display: none !important;
  }
}

.hidden {
  display: none;
  visibility: hidden;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}



.inline {
  display: inline-block;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.readmore--hidden {
  @extend .hidden;
}


.pt-1 {
  padding-top: 1rem;
}
