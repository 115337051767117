.roadmap {

	ol {
		padding: 0;
	}

	li {
		position: relative;
		display: flex;
		align-items: center;
		padding-left: $global-spacing*2;
		margin-bottom: $global-spacing*1.5;

		@include mq(sm-down) {
			padding-left: $global-spacing*1.75;
			margin-bottom: $global-spacing;
		}

		// Create circle in roadmap
		&:before {
			position: absolute;
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			width: $global-spacing;
			height: $global-spacing;
			border-radius: 50%;
			top: $global-spacing/8;
			left: 0;
			color: $primary-color;
			background: $white;
			font-size: $font-base-small;
			border: 2px solid $primary-color;
		}
		// Loop to steps roadmap
		@for $i from 1 through 10 {
			&:nth-child(#{$i}) {
				&:before {
		    		content: '#{$i}';
				}
			}
		}
		// Create line under circle to connect next item
		&:after {
			position: absolute;
			content: '';
			top: $global-spacing/4;
			left: $global-spacing/2; // half width off circle
			width: 1px;
			background: $light-gray;;
			height: calc(100% + #{$global-spacing*1.5}); // 100% height + margin-bottom
		}

		// Styling for last list item of roadmap
		&:last-of-type {
			margin-bottom: 0;

			&:after {
				display: none;
			}
		}

	}
}