.price-card {
	background-color: $white;
	border-radius: $global-radius;
	box-shadow: $global-shadow--dark;

	@include mq(md) {
		display: none;
	}

	&:not(:last-child) {
		margin: 0 0 1.25rem;
	}

  .is-garanteed {
    small {
      color: $primary-color;
      display: inline-block;
      margin-left: .25rem;
    }
  }

  .discounted {
    small {
      color: $secondary-color;
      display: inline-block;
      margin-left: .25rem;
    }
  }

	.price-card__wrapper {
		padding: $global-spacing--small;
	}

	.price-card__subtitle {
		font-weight: bold;
		margin: 0 0 0.25rem;
	}

	.price-card__meta {
		display: flex;
		font-weight: 500;

		> div {
			display: flex;
			align-items: center;

			&:not(:last-child) {
				margin: 0 0.7rem 0 0;
				padding: 0 0.7rem 0 0;
				border-right: $global-border;

				@include mq(md) {
				 margin-right: $global-spacing/2;
				 padding-right: $global-spacing/2;
			  }

        &.is-garanteed {
          > span {
            background-color: $primary-color;
            color: $white;
            padding: .25rem .5rem;
            border-radius: 3px;
          }
        }

			}
		}

		span {
			display: flex;
			align-items: center;
			line-height: 1;

			&:nth-child(2) {
				margin-left: $global-spacing--small / 2;
			}
		}
	}

	.price-card__content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 1rem 0 0;
	}

	.price-card__price {
		margin: 0;
		font-size: 1.8em;
		font-weight: 400;
		color: $primary-color;

    .previous-price {
      margin-right: .5rem;
    }
	}

	.price-card__button {
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 1;
		padding: $global-spacing/4;
		width: 100%;
		border-radius: 0;
		border: none;
		box-shadow: none;

		&:before {
			margin-right: $global-spacing--small / 2;
		}

		// When expanded
		&.expanded:before {
			transform: rotate(180deg);
		}
	}

	.price-card__details {
		max-height: 0px;
		overflow: hidden;
		transition: max-height 0.75s;

		ul {
			list-style: none;
			margin: 0;

			li {
				display: flex;
				padding: 0.5rem 1.25rem;

				&:nth-child(even) {
					background: $body-background;
					border-top: 1px solid darken($body-background, 2.5%);
					border-bottom: 1px solid darken($body-background, 2.5%);
				}

				span {
					display: block;
					font-weight: bold;
					min-width: 40vw;
				}

				p {
					margin: 0;
				}
			}
		}
	}
}

.price-card__ages {
	display: flex;

	&:not(:last-child) {
		margin: 0 0 0.75rem;
	}

	[data-icon] {
		margin: 0 0.5rem 0.5rem 0;
		font-size: 1rem;
		&:before {
			display: block;
		}
	}

	.availability__going {
		display: flex;
		margin-bottom: $global-spacing/8;
		padding: 0.2rem 0.5rem;
		background: $body-background;
		font-size: 1.4rem;
		color: $body-text;
		border: $global-border;
		border-radius: 50%;

		&:not(:last-child) {
			margin-right: $global-spacing/8;
		}
	}
}

.price-card__description {
	color: $gray;
}
