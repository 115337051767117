.testimonial {
	h3 {
		margin-bottom: 2em;
	}

	span {
		font-size: $font-base-small;
		color: $dark-gray;
	}
}

.testimonial__title {
	font-weight: $font-weight-bold;
	margin-bottom: 0;
}

.testimonial__subtitle {
	font-size: $small-heading-size ;
	margin-bottom: $global-spacing;
}

.testimonial__meta {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;

	&--small {
		@include mq(sm) {
			padding-right: 2.5em;
		}
	}
}

.testimonial__foto {
	width: 5rem;
	margin-right: $global-spacing/2;
	border-radius: 100%;
}

.testimonial__user {
	display: flex;
	align-items: center;
	margin: $global-spacing--small 0 0;
}

.testimonial__name {
	font-weight: $font-weight-bold;
	line-height: 1.1;
	margin: 0;
}

.testimonial__container {
	margin: 0 auto;
	border: $global-border;
	background-color: white;
	padding: $global-spacing $global-spacing--big;

		@include mq(sm-down) {
		padding: $global-spacing $global-spacing--small;
		margin-bottom: $global-spacing;
	}

}

.testimonial__img {
	@include mq(sm) {
		display: none;
	}
	@include mq(lgx) {
		display: block;
		padding-left: $global-spacing;
	}
}

.testimonial__photo {
	width: 100%;
	object-fit: contain;
	margin-bottom: $global-spacing/4;
}

.testimonial__item {
	position: relative;
	padding: $global-spacing 0 $global-spacing 3.35em;
	font-size: 1.7rem;
	border-bottom: $global-border;

	&:before {
			content: url('../img/quote.svg');
			position: absolute;
			left: 0;
			top: $global-spacing/2;
			width: $global-spacing*1.5;
	}
}

/* =========================================================================
*
* Tour guides
*
* ====================================================================== */

.tourguide__item {
	position: relative;
	padding: $global-spacing 0;
	border-bottom: $global-border;
}

.tourguide__head {
	@include mq(sm-down) {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
}

.tourguide__content {
	font-size: $font-base-small;
	padding-left: $global-spacing/4;

	.tourguide__quote {
		float: left;
		width: 5rem;
		margin: -0.8rem 1.2rem 0 -1rem;
	}
}

.tourguide__name {
	font-size: $font-base-large;
	font-weight: $font-weight-bold;
	margin-bottom: 0;
}

.tourguide__meta {
	font-size: $font-base-small;
	font-weight: $font-weight-normal;
	color: $gray;
}

.tourguide__photo {
	margin-bottom: $global-spacing/2;
	height: $global-spacing*4;
	width: $global-spacing*4;
	object-fit: cover;
	border-radius: 50%;
	border: 4px solid $white;
	box-shadow: $global-shadow-new;

	@include mq(md-down) {
		width: $global-spacing*3;
		height: $global-spacing*3;
		margin-right: $global-spacing/2;
	}
}
