/* =========================================================================
*
* Basic Hero
*
* ====================================================================== */

.hero {
  position: relative;
  background-color: $body-background;
  width: 100%;
  height: 19.3rem;

  @include mq(sm) {
    height: 36rem;
    overflow: hidden;
  }

  @include mq(sm-down) {
    box-shadow: 0px 1px 6px rgba(128, 128, 128, 0.24);
    outline: 1px solid rgba(0, 0, 0, 0.05);
  }

  .hero__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      font-family: "object-fit:cover; object-position:center";
    }
  }

  .hero__title {
    position: relative;
    z-index: 2;
    color: $white;
    font-size: $large-heading-size;
    display: inline-block;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);

    @include mq(sm-down) {
      box-shadow: 0px 0px 70px 40px rgba(0, 0, 0, 0.05);
      background-color: rgba(0, 0, 0, 0.05);
      font-size: 3.2rem;
      margin: 0;
    }
  }

  .hero__content {
    position: absolute;
    width: 100%;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    z-index: 2;

    @include mq(sm-down) {
      align-items: flex-start;
      top: 20%;
    }
  }
}

.hero__usp {
  background-color: $primary-color;
  color: $white;
  font-size: $font-base-small;
  font-family: $heading-font;
  padding: 1em 0;

  @include mq(sm-down) {
    font-size: 1em;
  }

  p[data-icon] {
    margin: 0;

    &:before {
      font-size: $font-base-large;
      vertical-align: text-bottom;
      margin-right: 0.5rem;
    }
  }
}

/* =========================================================================
*
* Hero `home` variant
*
* ====================================================================== */

.hero--home {
  @include mq(sm) {
    height: 48rem;
    max-height: 80vh;
    max-width: none;
  }
}

/* =========================================================================
*
* Hero `Single reizen` variant
*
* ====================================================================== */

.hero--single {
  padding: 0;

  .hero__image:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      to top,
      transparentize(black, 0.7) 0%,
      transparentize(black, 1) 25%,
      transparentize(black, 1)
    );
    z-index: 1;

    @include mq(sm-down) {
      background: linear-gradient(
        to top,
        transparentize(black, 0.7) 0%,
        transparentize(black, 1) 40%,
        transparentize(black, 1)
      );
    }
  }

  @include mq(sm-down) {
    height: 26rem;
    max-height: 60vh;
  }

  @include mq(lgx) {
    height: 500px;
    max-height: 80vh;
    min-height: 30rem;
  }

  .btn {
    width: 100%;
    margin-bottom: $global-spacing--small/2;
    padding: $global-spacing/8;
    font-size: 1.8rem;
    font-weight: $font-weight-medium;
    text-align: center;
  }

  .hero__content {
    top: auto;

    @include mq(md-down) {
      position: relative;
      > .container {
        // Hide price block on mobile
        display: none;
      }
    }
  }
}

.hero__trip {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: $global-spacing*0.75 0 0;
  color: white;
  background: $body-background;
  text-align: left;
  background: transparent;

  h1 {
    font-size: $small-heading-size;
    line-height: 1;
    text-shadow: 0px 2px rgba(0, 0, 0, 0.2);
    // background: rgba(0,0,0,0.1);
    // box-shadow: 0 0px 8px 15px rgba(0,0,0,.1);

    @include mq(md) {
      font-size: $medium-heading-size;
      max-width: 80vw;
    }

    @include mq(lg) {
      font-size: $large-heading-size;
    }
  }
}

.hero__trip-meta {
  display: flex;
  flex-wrap: wrap;

  @include mq(sm-down) {
    padding: $global-spacing/2 0 0;
    margin-bottom: -#{$global-spacing}/2;
  }

  @include mq(smx-down) {
    flex-direction: column;
  }
}

.hero__trip-meta--mobile {
  position: relative;
  background: $white;
  z-index: 1;
  border-bottom: 1px solid $lightest-gray;
}

.hero__trip-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: $global-spacing/1.75;

  @include mq(md) {
    margin-bottom: $global-spacing * 0.75;
  }

  &:not(:last-child) {
    margin-right: $global-spacing;

    @include mq(lg) {
      margin-right: $global-spacing * 2;
    }
  }

  p {
    margin: 0;

    &:first-of-type {
      font-size: 1.3rem;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      margin-bottom: $global-spacing/10;

      @include mq(md) {
        font-size: $font-base-smallest;
      }
    }

    & + p {
      font-size: $font-base-small;
      font-weight: $font-weight-light;
      line-height: 1;
      margin-left: -$global-spacing * 0.75;

      @include mq(md) {
        font-size: $font-base-large;
        margin-left: 0;
      }
    }
  }
}

.hero__trip-img {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  margin-right: $global-spacing/4;

  &:before {
    font-family: $icon-font !important;
    font-size: 15px;
    line-height: 1.4;
    color: $primary-color-hover;

    @include mq(md) {
      font-size: 20px;
      color: inherit;
    }

    @include mq(lgx) {
      margin-right: $global-spacing/2;
      font-size: 24px;
    }
  }

  // Date group
  &.date:before {
    content: "U";
  }

  // Airplane group
  &.airplane:before {
    content: "W";
  }

  &.suitcase:before {
    content: "F";
  }

  &.bus:before {
    content: "b";
  }

  &.train:before {
    content: "i";
  }

  // Icon group
  &.group:before {
    content: "a";
  }
}

.hero__trip-ages {
  display: flex;
  flex-wrap: wrap;
  margin-top: $global-spacing/10;
  margin-bottom: 0;

  @include mq(smx-down) {
    flex-wrap: nowrap;
    margin-left: -$global-spacing;
    overflow: auto;
  }

  li {
    flex-shrink: 0;
    font-size: 14px;
    border-radius: 1.2rem;
    padding: 0rem $global-spacing/5;
    border: 1px solid $light-gray;

    @include mq(md) {
      background: rgba(255, 255, 255, 0.3);
      border: 0;
      color: $white;
      font-weight: $font-weight-bold;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
    }

    &:not(:last-child) {
      margin-right: $global-spacing/4;
    }
  }
}
