.alignleft {
	display: inline;
    float: left;
    margin: 0 2rem 1rem 0;
}

.alignright {
	display: inline;
    float: right;
    margin: 0 0 1rem 2rem;
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}