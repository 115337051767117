// https://csswizardry.com/2013/04/shame-css/

// Override margin inline styling - Truspilot
.trustpilot-widget {
  margin: 0 !important;
}

// Te specifiek - Sub navigatie link
.sub-nav__link {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.action-row {
  margin-top: 1rem;
  div {
    margin-bottom: 1.5rem;
  }
}


a[href*="#"]:not(.no-scroll),
a[href*="#"].js-tab-link {
  scroll-behavior: smooth;
}

.price-card__comment {
  color: $darkest-gray;
}

main.newsletter {
  ul {
    margin-left: 1em;

    li {
      list-style-type: disc;
    }
  }
}
