// Navigation
.site-header {
  background-color: white;
  @include mq(nav-breakpoint-down) {
    box-shadow: 0px 1px 15px rgba(128, 128, 128, 0.24);
    // position: relative;
    position: sticky;
    top: 0;
    z-index: 11;
  }
}

#menu-toggle {
  display: none;
}

.label-toggle {
  display: none;
}

.nav-bar {
  display: flex;
  min-height: 5rem;
  z-index: 2;

  @include mq(md-down) {
    padding: 0;
  }

  @include mq(sm-down) {
    // background: white;
    position: relative;
    width: 100%;
  }

  &__logo {
    display: flex;
    align-items: center;
    position: relative;
    margin-right: $global-spacing;
    z-index: 2;

    @include mq(sm-down) {
      overflow: hidden;
      max-height: 5rem;
    }

    img {
      position: relative;
      width: 10rem;
      min-width: 7em;
      padding-top: 0.6rem;
    }
  }

  @include mq(nav-breakpoint-down) {
    min-height: auto;
    width: 100%;

    &__logo {
      top: 50%;
      left: 1.6rem;
      transform: translateY(-50%);
      width: 8rem;
      background-color: white;
      margin: 0;

      img {
        width: 8rem;
        min-width: auto;
      }
    }
  }
}

.main-nav {
  flex: auto;
  display: none;
  position: relative;

  @include mq(nav-breakpoint) {
    display: block;
  }

  &__top,
  &__bottom {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__top {
    @include full-background-color;
    justify-content: flex-end;

    li {
      padding: 0.4rem 0.8rem;
      font-size: $font-base-smallest;
      color: $dark-gray;
      display: flex;
      align-items: center;

      a {
        color: $dark-gray;
      }
    }

    &--right {
      display: inherit;
      margin-left: $global-spacing;

      a {
        margin-right: 0.5rem;
      }

      [data-icon]:before {
        padding-right: 0.25rem;
      }

      // whatsapp

      .whatsapp:hover {
        @include transition;
        color: #34af23;
      }

      [data-icon='f'] {
        color: #34af23;
      }
    }
  }

  &__bottom {
    align-items: center;
    position: relative;

    @include mq(sm) {
      height: 6.5rem;
    }

    li {
      white-space: nowrap;
      display: inline-block;
    }

    li:not(:last-of-type) a {
      margin-right: $global-spacing/3;
    }
  }

  .main-nav__bottom-list {
    display: flex;
    align-items: center;

    a {
      padding: $global-spacing/4 $global-spacing/2;
      color: $body-text;
      font-weight: $font-weight-medium;

      &:hover {
        color: $primary-color-hover;
      }
    }
  }
}

.toggle-dropdown {
  > a::after {
    content: 'r';
    padding-left: $global-spacing/8;
    color: $dark-gray;
    font-family: $icon-font;
    font-size: 12px;
    vertical-align: middle;
  }

  &:hover {
    .sub-nav {
      transform: translateY(0px);
      opacity: 1;
      visibility: visible;
      z-index: 3;
    }
  }
}

.sub-nav {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  display: flex;
  background: white;
  overflow: hidden;
  box-shadow: $global-shadow--dark;
  border-top: 2px solid $primary-color;
  // fading effect
  transform: translateY(10px);
  visibility: hidden;
  will-change: opacity;
  transition: all ease-in-out 0.3s;
  opacity: 0;
  margin-top: -15px; // menu disappears when trying to move to submenu CRUSJ#2240


  a {
    font-size: $font-base-small;

    &:not(.btn) {
      transition: 0.3s all;
      color: $body-text;
      line-height: 1.9;

      &:hover {
        color: $primary-color;
      }
    }
  }

  &__quick {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 30%;
    background: $body-background;
    padding: 0 $global-spacing--small/2 $global-spacing--small/2;
    ul {
      width: 100%;
      li {
        padding: $global-spacing--small/2 $global-spacing--small/2;
        &:not(:last-of-type) {
          border-bottom: $global-border;
        }
      }
    }
    .btn {
      display: flex;
      align-self: flex-end;
    }
  }
  &__all {
    display: flex;
    flex: 1;
    padding: 1.5rem;

    li {
      display: block;
    }
  }
  &__category {
    flex: 1;
    margin: 0 $global-spacing--small/2 $global-spacing--small/2 0;
    &:first-child {
      flex: 0 0 33%;
    }
    &:nth-child(2) {
      ul {
        display: inline-block;
        width: 49%;
      }
    }
    &-title {
      font-size: $font-base-small;
      font-weight: $font-weight-normal;
      padding: $global-spacing--small/2 0;
      color: $placeholder-color;
      border-bottom: $global-border;
    }
  }
}

// Search field
.search-form {
  position: absolute;
  // position: relative;
  margin-bottom: 0;
  // margin-left: auto;
  right: $global-spacing;
  top: 0;
  margin-top: 1rem;
  // max-width: 100%;

  @include mq('search-form') {
    right: unset;
  }

  input {
    @include transition;
    // padding-left: 4rem;
    padding: 0;
		min-height: 4rem;
		min-width: 0rem;
    // width: 0;
    width: auto;
    font-size: 0.95em;
    transition: all 0.5s ease-in;

    @include mq(lgx) {
      width: auto;
		}

		&:focus,
		&:valid {
			border-color: $primary-color;
			// min-width: 20rem;
    }
  }

  &__button {
    position: absolute;
    padding: 0;
    // left: 1.25rem;
    right: 1.25rem;
    background: none;
    border: none;
    padding-top: 0.6rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.8rem;
		box-shadow: none;
		pointer-events: none;

		input:valid + & {
			pointer-events: all;
		}

    &:before {
      line-height: 1.4;
    }

    &:hover,
    &:focus {
      border: none;
    }
  }
}

// Mobile menu

@include mq(nav-breakpoint) {
  .mobile-topnav,
  .mobile-menu,
  .click {
    display: none;
  }
}
@include mq(nav-breakpoint-down) {
  .mobile-topnav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 5rem;

    .mobile-topnav__logo {
      width: 8rem;
      margin-left: $global-spacing/2;
      padding-top: $global-spacing/4;

      img {
        width: 8rem;
      }
    }

    ul {
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 5rem;
        height: 100%;

        &:not(:last-of-type) {
          border-right: $global-border;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          &:before {
            color: $dark-gray;
          }
        }

        span {
          display: block;
          color: $dark-gray;
          margin-left: 0.5rem;
          font-size: 1.1rem;
          line-height: 1.4;

          @media screen and (max-width: 700px) {
            margin-left: 0rem;
          }
        }
      }
    }

    // Reisgraag rating in topbar

    a {
      display: flex;
      align-items: center;
    }

    .rating-block {
      background-color: $primary-color;
      color: $white;
      display: inline-block;
      font-size: 0.9rem;
      position: relative;
      border-radius: 100%;
      text-align: center;
      width: 25px;
      height: 25px;
      line-height: 23px;
      margin-right: 0.5rem;
      letter-spacing: -1px;

      @media screen and (max-width: 700px) {
        font-size: 0.8rem;
        width: 22px;
        height: 22px;
        line-height: 20px;
      }
    }

    .star-rating {
      @media screen and (min-width: 700px) and (max-width: 992px) {
        display: flex;
      }
    }

    .star-rating ul {
      display: flex;
      align-items: center;

      li {
        width: 0.9rem;
        height: 0.9rem;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 0.1rem;

        @media screen and (max-width: 700px) {
          width: 0.6rem;
          height: 0.6rem;
        }

        &.full {
          background-image: url('../img/star.svg');
        }
        &.half {
          background-image: url('../img/star-half.svg');
        }
      }
    }
  }

  .main {
    transition: 0.5s cubic-bezier(0.75, 0.02, 0.33, 1);
    // overflow-x: hidden;
  }

  .mobile-menu {
    position: fixed;
    width: 0px;
    right: 0;
    display: block;
    transition: 0.25s cubic-bezier(0.75, 0.02, 0.33, 1);
    background-color: $white;
    height: 100vh;
    max-height: 100vh;
    overflow: auto;
    z-index: 10;
    -webkit-overflow-scrolling: touch;

    .mobile-menu__wrapper {
      margin: $global-spacing--big * 2 $global-spacing $global-spacing--big * 2;
    }

    .mobile-menu__item {
      padding: 0;
      font-size: $font-base-large;
      opacity: 0;
      display: none;
      position: relative;
      transition: 0.25s ease;

      &:not(:first-child) {
        margin: $global-spacing*0.75 0 0;
      }

      > a {
        color: $body-text;
        font-weight: $font-weight-bold;
        text-decoration: underline;
      }
    }

    &__subitem a {
      display: flex;
      justify-content: space-between;
      color: $body-text;
      font-size: $font-base;

      i {
        transform: rotate(-90deg);
        font-size: 1.3rem;
        color: $darkest-gray;
      }
    }
  }

  .click {
    width: 3rem;
    height: 3rem;
    z-index: 99;
    cursor: pointer;
    display: block;
    border-radius: 50%;
    color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75em;
    transition: ease 0.5s;

    // When active change hamburger to close icon
    &.active {
      position: relative;
      z-index: 99;
      &:before {
        content: '\75';
      }
    }
  }
}
