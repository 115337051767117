.search-field {
  display: flex;

  @include mq(sm) {
    flex-wrap: wrap;
    overflow: hidden;
    max-width: 70rem;
    width: 100%;

    > * {
      width: 100%;
    }
  }

  @include mq(sm-down) {
    margin: 0;
  }

  .btn,
  input[type="text"] {
    @include mq(sm) {
      height: 5.5rem;
      border-radius: 0;
    }
  }

  .search-field__container {
    position: relative;
    justify-content: center;
    z-index: 1;
    box-shadow: $global-shadow;

    @include mq(sm-down) {
      justify-content: flex-start;
      background: white;
      padding: $global-spacing/2 $global-spacing/2 $global-spacing * 0.75;
      margin: 3.75rem 0 0;
    }

    .holidays-filter-box {
      width: 100%;

      @include mq(md) {
        box-shadow: 0px -32px 32px 10px rgba(0,0,0,0.1), 0px 32px 32px 10px rgba(0,0,0,0.1);
      }

      @include mq(sm) {
        display: flex;
        align-items: stretch;
        margin: 0 0 $global-spacing--small / 2;
      }

      @include mq(sm-down) {
        .select-field,
        button {
          width: 100%;
          > * {
            width: 100%;
          }
        }
      }

      .select-field {
        display: flex;

        @include mq(sm-down) {
          margin: 0 0 1rem;
        }
      }
    }
  }

  &__title {
    font-size: $font-base-large;
    margin-bottom: $global-spacing * 0.75;

    @include mq(sm) {
      display: none;
    }
  }

  input,
  select {
    flex: 1;
    // specify height search-field
    height: 3em;
    outline: none;
    border-radius: 0;
  }

  select {
    color: $placeholder-color;
  }

  input,
  select {
    background: #fafafa;
    border: 2px solid #f4f4f4;
  }

  &__date {
    text-align: left;
    @include mq(sm) {
      flex-grow: 2;
    }

    .icon-icon-agenda {
      left: 0;
      color: $primary-color;
      height: 1.5rem;

      + span {
        padding-left: 2.6rem;
        font-size: $font-base-small;
      }
    }
  }

  button {
    flex-grow: 0;
    margin: 0;

    @include mq(sm) {
      border: none;
      box-shadow: none;
      border-radius: 0;

      &:hover {
        border: none;
      }
    }
  }

  @include mq(sm-down) {
    flex-direction: column;
    flex-basis: 100%;

    > *:not(:last-child) {
      margin-bottom: $global-spacing / 2;
    }
  }
}

.search-form {
  &__button {
    color: $dark-gray;
    box-shadow: none;
  }
}

.icon-input {
  position: relative;

  i {
    position: absolute;
    line-height: 1;
    top: 50%;
    transform: translateY(-50%);
    left: 0.5rem;
  }

  > input,
  span {
    padding-left: 2rem;
  }
}

.mobile-search {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  transform: translateY(0);
  border-top: 1px solid $white;
  border-bottom: 2px solid $white;
  transition: all 0.25s ease-in;
  z-index: 3;
  height: 5rem;

  &__input {
    height: 100%;
		&[type="text"] {
			border: none;
			border-radius: 0;
		}
  }

  &__button {
    position: absolute;
    right: 0;
    appearance: none;
    background: transparent;
    box-shadow: none;
    border: none;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 1.25rem;
    color: $primary-color;
    font-size: 2.25rem;
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
    border-top: 1px solid $lightest-gray;
    border-bottom: 2px solid $primary-color;
    transform: translateY(100%);
  }
}
