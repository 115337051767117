.animated {
  opacity: 0;
  animation-duration: 1s;
  animation-fill-mode: both;

  &--slow {
  	opacity: 0;
  	animation-duration: 1.5s;
  	animation-fill-mode: both;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}