/*  Block
\*----------------------------------------------------------------------------*/
.label {
  display: inline-block;
  margin: 0;
  padding: 0 0.7rem;
  background: $body-background;
  color: $dark-gray;
  font-size: 1.3rem;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

/*  Elements
\*----------------------------------------------------------------------------*/

/*  Modifiers
\*----------------------------------------------------------------------------*/
