.social-icons {
	display: flex;

	li {
		background-color: $white;
	    border-radius: 100%;
	    height: 2.5em;
	    width: 2.5em;
	    display: flex;
	    justify-content: center;
	    margin-right: 1em;
	    display: flex;
	    align-items: center;
	    transition: transform 0.25s ease-out;

    	&:hover {
		    transform: translateY(-5px);
		}
	}

	a {
		font-size: 1.4em;
	}
	i {
		vertical-align: middle;
	}
}
