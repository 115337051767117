.btn,
%button,
button {
  display: inline-block;
  padding: $global-spacing/4 $global-spacing/2;
  background-color: $body-background;
  border: $global-border;
  border-radius: $global-radius;
  font-size: $font-base-small;
  font-family: $body-font;
  font-weight: $font-weight-normal;
  line-height: inherit;
  cursor: pointer;
  box-shadow: 0 3px 0 0 darken($body-background, 5%);
  white-space: nowrap;
  transition: 0.25s;

  &.fancybox-button {
    box-shadow: none;
  }

  &:hover,
  &:focus {
    border: 1px solid $dark-gray;
    outline: none;
  }

  &.btn--xs {
    padding: $global-spacing/8 $global-spacing/4;
    font-size: 1.5rem;

    @include mq(md-only) {
      padding: $global-spacing/8;
    }
  }

  &--big {
    font-size: $font-base-large;
  }

  &--large {
    font-size: $font-base-large;
  }

  &--primary {
    background-color: $primary-color;
    color: white;
    border-color: $primary-color;
    box-shadow: 0 3px 0 0 $primary-color-hover;

    &:hover {
      color: $white;
      background-color: $primary-color-hover;
      border-color: $primary-color-hover;
    }
  }

  &--secondary {
    background-color: $secondary-color;
    color: white;
    border-color: $secondary-color;
    box-shadow: 0 3px 0 0 $secondary-color-hover;

    &:hover
    &:focus {
      color: $white;
      background-color: $secondary-color-hover;
      border-color: $secondary-color-hover;
    }
  }

  &--white {
    background-color: $white;
    color: $primary-color;

    &:hover,
    &:focus {
      border: $global-border;
    }
  }

  &--full {
    display: block;
    text-align: center;
  }

  &--center {
    display: table;
    margin: 0 auto;
  }

  &--outline {
    border: 1px solid $primary-color;
  }

  + .btn--full {
    margin-top: $global-spacing--small; // create space between full-width buttons
  }
}

button {
  font-family: $body-font;
  cursor: pointer;
}

%read-more,
.read-more {
  @include button-icon("Q", after);
}

.previous {
  @include button-icon("Q", before, 15px, $global-spacing/2);
  color: $darkest-gray;
  // Rotate icon & change order
  &::before {
    order: -1;
    position: relative;
    top: 2px;
    transform: rotate(180deg);
    transition: $global-transition;
  }

  &:hover::before,
  &:focus::before {
    transform: translateX($global-spacing/4) rotate(180deg);
  }
}

form button,
form .btn {
  margin: 0;
}

%badge, // Placeholder for badge
.badge {
  padding: $global-spacing/8;
  color: $white;
  background: $secondary-color;

  @include mq(sm-down) {
    font-size: $font-base-smallest;
  }
}

.link {
  @include button-icon("Q", after);
  font-size: $font-base;

  section > & {
    @include mq(sm) {
      display: none;
    }
  }

  &:before {
    font-size: 1.4rem;
  }
}
