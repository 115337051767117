.widget {


  &--small {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -1.5rem;
    color: white;
    font-size: 1.1rem;
    font-family: $heading-font;
    font-weight: 400;
    padding-bottom: 1.2rem;

    > div {
      display: flex;
      span {
        // order: 2;
        // margin-left: 0.6rem;
        // text-shadow: 0px 1px 2px rgba(200, 200, 200, 0.5);
        // box-shadow: 0px 0px 70px 40px rgba(0, 0, 0, 0.05);
        // background-color: rgba(0, 0, 0, 0.05);
      }
    }

    .widget__logo {
      img {
        align-self: center;
        width: 8.5em;
        margin-left: 1.25rem;
      }
    }

    .hero & {
      @include mq(sm-down) {
        display: none;
      }
    }
  }

  @include mq(sm-down) {
    &--small {
      margin: 1rem 0;
      padding: 0;
      font-size: 0.8rem;
      align-items: center;
      box-shadow: 0px 0px 70px 40px rgba(0, 0, 0, 0.35);
      background-color: rgba(0, 0, 0, 0.35);

      span {
        font-size: 1rem;
      }
    }
    .widget__stars {
      display: none;
    }
  }
}
