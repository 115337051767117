/* =========================================================================
*
* ekomi-widget
* - Appears on smaller screen beneath header
*
* ====================================================================== */

section.trustpilot {
  display: none; // Hide on default
}


.ekomi-widget-container {
  display: block;
  min-height: 5rem;
  padding: $global-spacing/3;

  text-shadow: 0px 0px 3px rgba(255, 255, 255, 0.7);

  a {
    color: $body-text !important;
    font-size: $font-base-smallest;
  }
}

.ekomi-widget-score {
  padding: 0 $global-spacing/4;
  color: $primary-color;
  font-weight: $font-weight-bold;
  text-align: left;
  text-decoration: none;

  span.reviews-rating,
  span.reviews-rating-max {
    font-size: 2.2rem;
  }

  .widget-reviews-count {
    font-size: 1.2rem;
  }
}



@include mq(sm-down) {
  section.trustpilot {
    display: block;
    min-height: 11.5rem;
  }

  .ekomi-widget-container {
    background: $body-background;

    & a {
      font-size: 1.2rem;

      span.reviews-rating,
      span.reviews-rating-max {
        font-size: 1.4rem;
        font-weight: 600;
      }
    }

    .stars-white {
      width: 132px;
    }

    .ekomi-widget-score {
      font-weight: 400;
      justify-content: space-between !important;
    }

  }



  // .widget-reviews-count {
  //   font-size: 1.2rem;
  // }
}

