
.booking-overview {
  .row {
    margin-bottom: 0.7rem;

    > div:first-child {
      color: $dark-gray;
      font-weight: $font-weight-medium;
    }

    > div:nth-child(2) {
      padding-left: 3rem;
    }
  }
}
