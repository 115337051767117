.introduction {
  @include mq(sm-down) {
    margin: 17rem 0 0;

    > div:nth-child(1) {
      display: none;
    }

    .block {
      border: none;
      padding: 0;
    }
  }

  .btn {
    margin-bottom: $global-spacing;
  }
}

.video {
  @include mq(lgxx) {
    padding-left: $global-spacing * 2;
    padding-right: $global-spacing * 2;
  }

  @include mq(sm-down) {
    > * {
      width: 100%;
    }
  }
}

.card__image-wrapper {
  .banner__vertrekgarantie--with-discount {
    top: unset;
    bottom: 1.2rem;
  }
}

