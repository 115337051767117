// Transition
@mixin transition($transition: 0.25s all ease-in) {
  transition: $transition;
}

// Full width background
@mixin full-background-color($color: $body-background) {
  position: relative;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    background-color: $color;
    top: 0;
    bottom: 0;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
  }
}

@mixin button-icon(
  $icon,
  // Choose icon from mapping (for example 'a')
    $position: before,
  // Wether before or after the text
    $icon-size: inherit,
  // Needs to be set in pixels for IE
    $icon-spacing: $global-spacing/4 // Spacing between icon and content
) {
  display: flex;
  align-items: center;

  &::before {
    font-family: $icon-font;
    content: $icon;
    font-size: $icon-size;
    line-height: 1;
  }

  @if $position == before {
    &::before {
      margin-right: $icon-spacing;
    }
  } @else if $position == after {
    &::before {
      order: 1;
      margin-left: $icon-spacing;
    }
  } @else {
    @error "Unknown #{$position}";
  }
}

@mixin expand-container {
  margin-left: -(map-get($grid-gutter-width, "medium"));
  margin-right: -(map-get($grid-gutter-width, "medium"));

  @include mq(sm-down) {
    margin-left: -(map-get($grid-gutter-width, "small"));
    margin-right: -(map-get($grid-gutter-width, "small"));
  }
}

// Hide element
@mixin hide-element {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

@mixin center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

// =============================================================================
// Clean list
// =============================================================================

@mixin clean-list() {
  list-style: none;
  margin: 0;
  padding: 0;
}

// =============================================================================
// Clean button
// =============================================================================

@mixin clean-button() {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  color: inherit;
  font-weight: inherit;
  text-align: inherit;
}

// =============================================================================
// Clean link
// =============================================================================

@mixin clean-link() {
  text-decoration: none;
  color: inherit;

  &:hover, &:focus {
    text-decoration: none;
  }
}
