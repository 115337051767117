// Alert
%alert-box, // Placeholder for alert
.alert-box {
  padding: $global-spacing/2 $global-spacing*0.75;
  background: $alert-info;
  border: 1px solid $alert-info-border;
  text-align: left;
  max-width: 100rem;

  > * {
    margin: 0;
  }

  .alert-box-title {
    font-weight: $font-weight-bold;
  }

  @include mq(sm-down) {

    p:not(.alert-box-title) {
      font-size: $font-base-smallest;
    }
  }

  &.alert--icon {
    position: relative;
    padding-left: $global-spacing*2;

    // Icon
    &::before {
      @include center-y;
      content: '';
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      position: absolute;
      left: $global-spacing/2;
      width: $global-spacing;
      height: $global-spacing;
      font-family: $icon-font;
    }
  }

  &.alert--success {
    background: $alert-success;
    border-color: $alert-success-border;

    &::before {
      content: 'c';
      color: $alert-success-border;
      font-weight: $font-weight-bold;
      border: 2px solid $alert-success-border;
      border-radius: 50%;
    }
  }

  &.alert--suspended {
    &::before {
      content: 'R';
      font-size: 24px;
    }
  }
}