.filter {
  @include mq(sm) {
    max-width: 100%;
    flex-basis: 100%;
  }

  @include mq(lg) {
    padding-right: 1.5em;
    max-width: 30%;
    flex-basis: 30%;
  }

  @include mq(lgx) {
    max-width: 25%;
    flex-basis: 25%;
  }

	padding-left: 0;

  .filter__empty {
    display: flex;
    align-items: baseline;
    margin: $global-spacing/10 0 0;
    padding: $global-spacing--small/6 0;
    font-size: $font-base-small;
    color: $dark-gray;
    transition: $global-transition;

    .filter__icon {
      font-size: 1.1rem;
    }

    &:hover {
      color: $darkest-gray;

      .filter__icon {
        color: $darkest-gray;
      }
    }
  }

  .filter__head {
    @include mq(sm) {
      border-bottom: 1px solid $body-background;
    }
  }

  .filter__title {
    margin-top: 0;
    font-size: $font-base-large;
    font-weight: $font-weight-bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;

    i {
      position: relative;
      transition: 0.25s;
      cursor: pointer;
      top: 3px; // Visually centered

      // Expand click area
      &::after {
        @include center;

        content: "";
        width: 3rem;
        height: 3rem;
      }

      &.active {
        transform: scaleY(-1);
      }
    }
  }

  .filter__title--small {
    font-size: $font-base-small;
    font-weight: $font-weight-medium;
    padding: $global-spacing/3 0;
    border-top: 1px solid whitesmoke;
    margin: 0;
  }

  .filter__options {
    display: inline-block;
    padding-bottom: $global-spacing/2;
  }

  .filter__selected {
    display: inline-flex;
    align-items: center;
    font-size: $font-base-smallest;
    padding: $global-spacing/6 $global-spacing/3;
    color: $body-text;
    border: $global-border;

    &:not(:last-of-type) {
      margin: 1.25em 0 0.5em;
    }
  }

  &__icon {
    display: flex;
    color: $dark-gray;
    cursor: pointer;
    transition: 0.35s;

    &:not(.filter__icon--left) {
      margin-left: $global-spacing--small/2;

      @include mq(sm-down) {
        margin-left: 0.3rem;
      }
    }

    &--left {
      margin-right: $global-spacing--small/2;
    }

    &--orange {
      color: $primary-color;
    }
  }

  &__date {
    position: relative;

    .date {
      position: relative;
      z-index: 1;
      background: none;
    }

    i {
      position: absolute;
      right: 1em;
      top: 0.6em;
    }
  }

  &__checkbox {
    height: auto;
    outline: none;
    width: 1.5em;

    &:checked + .filter__label {
      font-weight: 400;
      color: $primary-color;
    }
  }

  &__label {
    width: 85%;
    display: inline-block;
    margin-bottom: 0.2em;
    user-select: none;

    &::after {
      display: none;
    }
  }

  select {
    font-weight: 300;
    font-size: $font-base-small;
  }
}

input:checked + .filter__title > .filter__icon {
  transform: scaleY(-1);
  line-height: 1.5em;
}

.filter__toggle:checked ~ .filter__options {
  display: none;
}

.filter__item {
  font-size: 1.6rem;
}

.filter__block {
  min-height: $global-spacing * 2;
  margin-bottom: $global-spacing;
  padding: $global-spacing--small;
  background-color: white;
  border-radius: $global-radius;

  @include mq(sm) {
    overflow: hidden;
  }

  @include mq(lg) {
    height: auto;
  }

  &:not(:last-child):not(.filter__block--alt) {
    border: $global-border;
    margin-bottom: $global-spacing/2;
  }

  &.collapsed {
    @include mq(sm-only) {
      height: auto;
    }

    @include mq(sm) {
      height: $global-spacing * 2;
    }
  }
}

.desktop-filters {
	@include mq(md-down) {
		display: none;
	}

	margin: 0 -#{$global-spacing/2};

  .filter__item {
    padding-left: $global-spacing/2;
    padding-right: $global-spacing/2;
  }
}


// Everything below is for the mobile filter

.filter__block--alt {
  @include mq(sm-down) {
    border: none;
    padding: 0 0 $global-spacing--small;
    background: none;

    .filter__head,
    + .filter__empty {
      display: none;
    }

    .filter__selected {
      background: white;
      font-size: 1.4rem;
      margin: 0;

      &:not(:last-of-type) {
        margin-top: 0;
      }
    }
  }
}

.filter__blocks {
  @include mq(md-down) {
    position: fixed;
    top: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding-bottom: 5rem;
    z-index: 9998;
    background: white;
    transform: translateY(100vh);
    overflow: scroll;
    transition: $global-transition;

    &.open {
      transform: translateY(0);
    }
  }
}

.filter__button {
  display: none;

  @include mq(md-down) {
    display: block;
    position: fixed;
    bottom: 4rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;

    // Icon
    &::before {
      padding-right: $global-spacing/4;
      vertical-align: middle;
    }

    // Counter active filters
    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 2.5rem;
      height: 2.3rem;
      margin-left: $global-spacing/4;
      padding: 0.1rem 1rem 0.3rem;
      background: #CE8000;
      line-height: 1;
      border-radius: 0.2rem;
    }
  }
}

.mobile-filters {

	@include mq(md) {
		position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
		left: 50%;
	}

	margin-top: $global-spacing/2;
  margin-left: auto;
  margin-right: auto;

  * + * {
    margin-top: $global-spacing/2;
  }

  button {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .departure_guarantee {
    margin-top: 0;
  }

}
