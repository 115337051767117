// Class is also provided by Javascript
.iframe-container {
  position: relative;
  display: block;
  padding-top: percentage(9/16);

  iframe {
    position: absolute;
    top: 0;
    left:0;
    height: 100%;
    width: 100%;
  }
}