/* =========================================================================
*
* Introduction
*
* ====================================================================== */

.single-intro {
	&__content {
		@include mq(sm) {
			font-size: $font-base-large;
		}
	}

	@include mq(lgxx) {
		padding: $global-spacing 0 $global-spacing*2;
	}
}

.single-prices {
	padding: $global-spacing 0;
	@include mq(sm-down) {
		text-align: left;
	}
}

.single-prices__title {
	flex-grow: 1;
}

.travel-summary {
	position: relative;
	padding: $global-spacing;
	padding-right: 0;
	background-color: $body-background;

	&:after {
		content: '';
		position: absolute;
		left: 100%;
		top: 0;
		background-color: $body-background;
		height: 100%;
		width: 100vw;
		@include mq(sm-down) {
			left: -10%;
			width: 110vw;
			z-index: -1;
		}
	}

	> div:not(:last-of-type) {
		border-bottom: $global-border;
		padding-bottom: $global-spacing--small;
		margin-bottom: $global-spacing;
	}

	@include mq(sm-down) {
		width: 100%;
		padding-left: 0;
	}
}

.travel-info {
	li {
		display: flex;
		align-items: center;
		margin: $global-spacing/2 0;
	}

	i {
		background-color: $white;
		border-radius: 100%;
		height: 5.6rem;
		flex: 0 0 5.6rem;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: $global-spacing/2;

		@include mq(md-only) {
			margin-right: $global-spacing/4;
		}

		&:before {
			align-self: center;
			transform: scale(1.25);
		}
	}

	.icon-icon-plain:before {
		content: url('../img/airplaine.svg');
	}
	.icon-icon-agenda:before {
		content: url('../img/date.svg');
	}
	.icon-icon-group:before {
		content: url('../img/group.svg');
	}
	.icon-icon-bus:before {
		content: 'b';
	}
	.icon-icon-own-transport:before {
		content: 'F';
	}

	.icon-icon-bus:before,
	.icon-icon-own-transport:before {
		transform: scale(1.5);
		font-family: 'crusj';
	}

	// Needs to be defined for IE / Edge
	.icon-icon-plain:before,
	.icon-icon-agenda:before,
	.icon-icon-group:before {
		display: block;
		width: 1.9rem;
		height: 2rem;
	}

	&--rating {
		i {
			border: 2px solid $primary-color;
			font-style: normal;
			font-size: 2.25rem;
			line-height: 5.2rem;
			font-weight: 400;
		}
	}

	&__content {
		display: inline-block;

		span:not(.tooltips__inner) {
			display: block;
			font-size: $font-base-smallest;
			font-weight: 300;
		}

		p {
			margin: 0;
			padding: 0;
			font-weight: 400;
		}
	}
}

.price-block {
	@extend .block;
	@extend .block__content;
	max-width: 28rem;
	text-align: left;
	position: relative;
	width: calc(100% - #{$global-spacing/4}); // .5 rem due discount block
	padding: $global-spacing--small;
	margin-bottom: $global-spacing;
	z-index: 1;

	.hero--single & {
		max-width: 33.5rem;
		border-radius: $global-radius;
		border: 0;
		margin-left: auto;

		p {
			margin: $global-spacing/4 0 $global-spacing/2;
			font-size: 3rem;
		}
	}

	p {
		display: flex;
		flex-wrap: wrap;
		margin: 0;
		font-size: $font-base-large;
		font-weight: $font-weight-normal;
		color: $primary-color;
	}

	.note {
		font-size: $font-base-small;
		color: $dark-gray;
	}

	// meta
	.meta {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -#{$global-spacing/8};

		span {
			display: flex;
			align-items: center;
			margin: 0 $global-spacing/8 $global-spacing/4;
			font-size: $font-base-smallest;
			font-weight: $font-weight-normal;
			color: $darkest-gray;

			&:not(:last-child) {
				padding-right: $global-spacing*0.375;
				border-right: $global-border;
			}

			&:last-child::before {
				margin-right: $global-spacing/4;
			}
		}
	}

  .option-container {
    text-align: center;
  }

  .banner__vertrekgarantie {
    &--with-discount {
      top: -4rem !important;
      right: -$global-spacing/2 !important;
    }
  }
}

/*=============================================
=            Section comment block            =
=============================================*/

.previous-price {
	align-self: center;
	margin-right: $global-spacing/2;
	margin-bottom: -$global-spacing--small/2; // when wrap less space between elements
	color: $dark-gray;
	font-size: $font-base;
	text-decoration: line-through;
}

/*======================================
=            Discount block            =
======================================*/

.discount {
	position: absolute;
	top: $global-spacing/2;
	right: -$global-spacing/4;
	padding: 0.1rem 1rem 0.2rem;
	background-color: $secondary-color;
	color: $white;
	font-weight: 500;
	z-index: 2;

	&:after {
		content: '';
		position: absolute;
		top: 100%;
		right: 0;
		transform: rotate(180deg);
		border-right: 4px solid darken($secondary-color, 15%);
		border-left: 4px solid rgba(0, 0, 0, 0);
		border-bottom: 3px solid darken($secondary-color, 15%);
		border-top: 3px solid rgba(0, 0, 0, 0);
	}

	&--left {
		left: -$global-spacing/4;
		right: auto;
		top: $global-spacing*0.375;
		&:after {
			transform: scaleX(-1) rotate(180deg);
			left: 0;
			right: auto;
		}
	}

	.price-block & {
		top: -$global-spacing/2;
		font-size: 1.7rem;
	}
}

.banner__vertrekgarantie {
  @extend .discount;

  background-color: $primary-color;

  &:after {
    border-right: 4px solid darken($primary-color, 15%) !important;
    border-bottom: 3px solid darken($primary-color, 15%) !important;
  }


}

/*=========================================
=            Fixed price block            =
=========================================*/

.sticky-offer {
	position: absolute;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: $global-spacing/2 $global-spacing/2;
	z-index: 2;
	transition: all 0.5s cubic-bezier(0.55, 0.06, 0, 1.11);
	transform: translateY(100%);
	border-top: $global-border;
	border-bottom: $global-border;
	background: $white;
	opacity: 0;

	&.open {
		display: flex;
		position: fixed;
		opacity: 1;
		visibility: visible;
		transform: translateY(0%);
		bottom: 0;
	}

	@include mq(sm) {
		top: 0;
		transform: translateY(-100%);

		&.open {
			bottom: auto;
		}
	}

	.sticky-offer-price {
		display: flex;
		align-items: baseline;

		> * {
			margin: 0 $global-spacing--small 0 0;
		}
	}
}

.detail-info {
	aside {
		@include mq(md-only) {
			padding-left: 0;
		}
	}
}

.guarentees {
	@include mq(lgx) {
		margin-top: -$global-spacing*2;
	}
}

/* =========================================================================
*
* Tab section
*
* ====================================================================== */

.travel-tab {
	@include mq(lgx) {
		@include full-background-color($body-background);
		padding: $global-spacing*2 0;
		margin-bottom: -#{$global-spacing*1.5};
	}
}

/* =========================================================================
*
* Package - included & excluded
*
* ====================================================================== */

.package {
	display: flex;
	justify-content: space-between;
	width: 100%;

	@include mq(sm-down) {
		flex-wrap: wrap;
		padding: $global-spacing 0;
	}

	h3 {
		color: $body-text;
		font-size: $small-heading-size;
	}

	.package__list {
		flex: 1 0 320px;

		li {
			padding-right: $global-spacing/2;
		}
	}
	// Variant for excluded variant
	.package__list-excluded {
		li:before {
			content: 'L'; // exclude icon
			color: $dark-gray;
		}
	}
}


/* =========================================================================
*
* Program
*
* ====================================================================== */

.detail-location-content {
	@include mq(sm) {
		margin-bottom: $global-spacing * 2;
	}

	.js-triggerModal {
		cursor: pointer;
	}

	// Porgram list
	.detail-program-list {
		display: flex;
		flex-wrap: wrap;
		margin-top: $global-spacing;
		margin-left: -$global-spacing--small/2;
		margin-right: -$global-spacing--small/2;

		// Item
		.detail-program__item {
			transition: 0.5s all;
			width: calc(25% - #{$global-spacing--small});
			margin: 0 $global-spacing--small/2 $global-spacing--small;
			cursor: pointer;

			// Responsive
			@include mq(md-only) {
				width: calc(33% - #{$global-spacing--small});
			}

			@include mq(sm-down) {
				width: calc(50% - #{$global-spacing--small});

				p {
					line-height: 1.2;
				}
			}

			img {
				display: block;
				width: 100%;
			}

			p {
				font-weight: $font-weight-bold;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
				overflow: hidden;
				margin: $global-spacing/4 0 0;
			}

			// Hover state
			&:hover,
			&:focus {
				p {
					color: $primary-color;
				}
			}
		}

		p {
			margin-bottom: 0;
		}
	}
}

/* =========================================================================
*
* Practical information
*
* ====================================================================== */

.detail-practical {
	max-width: 1140px;
	margin: 0 auto;
	padding: 0;
}
