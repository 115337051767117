$site-breakpoints: (
  'smx-down': '(max-width: 380px)',
  'sm': '(min-width: 480px)', // Mobile-landscape (and larger)
  'sm-only': '(min-width: 0) and (max-width: 480px)',
  'sm-down': '(max-width: 767.98px)',
  'md': '(min-width: 768px)', // Tablet-portrait (and larger)
  'md-only': "(min-width: 768px) and (max-width: 992px)",
  'md-down': "(max-width: 991.98px)",
  'lg' : "(min-width: 992px)", // Tablet-landscape (and larger)
  'lg-only' : "(min-width: 992px) and (max-width: 1200px)",
  'lg-down' : "(max-width: 1199.98px)",
  'lgx' : "(min-width: 1200px)", //	Laptops (and larger)
  'lgx-only' : "(min-width: 1200px) and (max-width: 1440px)",
  'lgx-down' : "(max-width: 1199.98px)",
  'lgxx' : "(min-width: 1440px)", // Desktop (and larger)
  'lgxx-only' : "(min-width: 1440px) and (max-width: 1800px)",
  'lgxx-down' : "(max-width: 1799.98px)",
  'lgxxx' : "(min-width: 1800px)",
  'nav-breakpoint' : "(min-width: 992px)",
  'nav-breakpoint-down' : "(max-width: 991.98px)",
  'search-form': '(min-width: 992px) and (max-width: 1064px)'
);

@mixin mq($name) {
  @if $name == 'landscape' or $name == 'portrait' {
    $mq-media-query: 'screen and (orientation: #{$name})';
    @media #{$mq-media-query} {
      @content;
    }
  }
  @else if $name == 'retina' {
    $mq-media-query: 'screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 2dppx)';
    @media #{$mq-media-query} {
      @content;
    }
  }
  @else {
    @if map-has-key($site-breakpoints, $name) {
      $mq-media-query : 'screen and #{map-get($site-breakpoints, $name)}';
      @media #{$mq-media-query} {
        @content;
      }
    }
    @else {
      @warn "Unfortunately, no value could be retrieved from `#{$site-breakpoints}`. " + "Please make sure it is defined in `$breakpoints` map.";
    }
  }
}
