.page {

	&__content {
		@include mq(sm) {
			padding: $global-spacing $global-spacing $global-spacing 0;
		}

		&--left {
			@include mq(sm) {
				padding: $global-spacing $global-spacing--big $global-spacing 0;
			}
		}

		&--full-width {
			padding: $global-spacing--big 0;
		}

	}

	&__sidebar {
		margin-top: $global-spacing*2.25;
	}

}

.page__content--intro {
	font-weight: $font-weight-normal;
}