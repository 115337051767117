// Typography

@font-face {
  font-family: "sofia";
  src: url("../fonts/sofiaproregular-webfont.woff2") format("woff2"),
    url("../fonts/sofiaproregular-webfont.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "sofia";
  src: url("../fonts/sofiaprolight-webfont.woff2") format("woff2"),
    url("../fonts/sofiaprolight-webfont.woff") format("woff");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "sofia";
  src: url("../fonts/sofiapromedium-webfont.woff2") format("woff2"),
    url("../fonts/sofiapromedium-webfont.woff") format("woff");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "sofia";
  src: url("../fonts/sofiaprobold-webfont.woff2") format("woff2"),
    url("../fonts/sofiaprobold-webfont.woff") format("woff");
  font-weight: 600;
  font-display: swap;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "sofia", sans-serif;
  font-weight: 300;
  font-size: $font-base;
  line-height: $base-line-height;
  color: $body-text;
}

// Block Tags
p,
blockquote,
hr,
pre,
ol,
ul,
dl,
table,
fieldset,
figure,
address,
form {
  margin-top: 0;
  margin-bottom: $text-margin-bottom;
}

h1,
h2,
h3 {
  font-family: $heading-font;
  font-weight: $font-weight-bold;
}

h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
  margin-bottom: $text-margin-bottom;
  line-height: $heading-line-height;

  p + & {
    margin-top: $global-spacing;
  }

  @include mq(sm-down) {
    margin-bottom: $global-spacing--small;
  }
}

h1 {
  font-size: $large-heading-size;
  line-height: $heading-line-height;

  @include mq(sm-down) {
    font-size: $medium-heading-size;
  }

  ~ h3 {
    color: inherit;
  }
}

h2 {
  font-size: $medium-heading-size;
  line-height: $heading-line-height;
}

h3 {
  font-size: $small-heading-size;

  h2 + &,
  p + & {
    font-size: $font-base-large;
    margin-bottom: $global-spacing/3;
  }
}

h4 {
  font-size: $font-base-large;
  margin-bottom: $global-spacing/4;
}

h5 {
  font-size: $font-base-large;
  margin-bottom: $global-spacing/4;
}

a {
  text-decoration: none;
  color: $primary-color;
  font-weight: 400;
}

blockquote {
  margin: 0;
}

dt {
  color: $dark-gray;
  font-size: $font-base-smallest;
  font-weight: $font-weight-medium;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

dd {
  font-weight: 400;
  margin-left: 0;
  margin-bottom: $global-spacing/2;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-small {
  font-size: 1.4rem !important;
}

.thin {
  font-weight: 300;
}
