.booking-intro {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.help {
	text-align: right;

	i {
		font-size: 2em;
		color: $primary-color;
	}

	&__content {
		@extend .travel-info__content;
		font-size: 1.2em;
		text-align: left;
		padding-left: $global-spacing/4;
	}
}

.booking {
	@include mq(sm-down) {
		.intro {
			font-size: $font-base-small;
		}

		aside > .block {
			display: none;
		}
	}

	@include mq(md-only) {
		aside {
			margin-top: $global-spacing*2;
			padding-left: 0;
		}
	}
}

/*======================================
=            Thank you page            =
======================================*/

.head-booked {
	@include full-background-color($white);
	padding: $global-spacing 0;
	margin: 0 auto $global-spacing;
	text-align: center;

	.alert-box {
		margin-left: auto;
		margin-right: auto;
		max-width: 50rem;
	}
}

// Variant sidebar booked-page
.page-booked {
	padding-top: 0;

	.page__sidebar {
		margin-top: 0;

		@include mq(sm) {
			padding-left: $global-spacing;
		}
	}
}
