// Colors
$primary-color: #e79000;
$primary-color-hover: darken($primary-color, 5%);
$secondary-color: #88ae81;
$secondary-color-hover: darken($secondary-color, 5%);
$white: #fff;
$body-background: #f9f9f9;
$lightest-gray: #e8e8e8;
$light-gray: #d8d8d8;
$gray: #b1b1b1;
$dark-gray: #979797;
$darkest-gray: #505050;
$black: #000000;
$body-text: #444;
$heading-color: #444;
$placeholder-color: darken($body-background, 25%);
$input-background-color: #fafafa;
$input-border-color: #f4f4f4;
$error-color: #f1453d;

// Alerts
$alert-info: #f7d88e;
$alert-info-border: #dabe7b;
$alert-success: rgba(#d4eccf, 0.7);
$alert-success-border: #88ae81;

// Global
$global-spacing: 3.2rem;
$global-spacing--small: 2rem;
$global-spacing--big: 4rem;

// Sections & components
$section-margin: $global-spacing; // Spacing between sections
$comp-margin: $global-spacing; // Spacing between margins

$grid-gutter-width: (
  small: $global-spacing/2,
  medium: $global-spacing
);

$body-font: "sofia";
$heading-font: Rubik, "sofia";
$heading-font--alt: Rubik, "sofia";
$heading-line-height: 1.1;
$base-line-height: 1.7;
$font-base: 1.8rem;
$font-base-large: 2rem;
$font-base-small: 1.6rem;
$font-base-smallest: 1.5rem;
$text-margin-bottom: $global-spacing*.75;
$icon-font: "crusj-icon";

$large-heading-size: 3.8rem;
$medium-heading-size: 2.6rem;
$small-heading-size: 2.4rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

$global-transition: all 0.25s ease-in;

$global-radius: 0.4rem;
$global-border: 1px solid $lightest-gray;
$global-shadow: 0px 0px 10px rgba(128, 128, 128, 0.14);
$global-shadow--dark: 0px 0px 10px rgba(128, 128, 128, 0.28);
$global-shadow-new: 0 6px 12px 1px rgba(0, 0, 0, 0.14); // @todo Check wether this can replace `$global-shadow`

// Grid
$grid-gutter: -$global-spacing/3;

// Animations
$global-cubic: cubic-bezier(0.75, 0, 0.16, 1);
$global-cubic-animation: 0.8s cubic-bezier(0.75, 0, 0.16, 1);

// Others
$fixed-footer-height: $global-spacing * 2;
