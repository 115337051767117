.selects-wrapper {
  display: flex;
  max-width: 50rem;

  .select-field {
    &:not(:last-child) {
      margin: 0 $global-spacing * 0.25 0 0;
    }
  }
}

.select-field {
  position: relative;
  width: auto;
  flex-grow: 1;

  &:after {
    content: "\72";
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.5rem;
    color: $gray;
    font-family: $icon-font;
    pointer-events: none;
  }

  select {
    padding-right: 1.3em;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
  }
}

.select-table {
  padding: $global-spacing 0;
  min-width: $global-spacing*4.5;
  max-width: calc(100vw - #{$global-spacing * 2.25});
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  &__wrapper {
    display: flex;
    white-space: nowrap;
  }
}

.select-table-item__wrapper {
  &:not(:last-child) {
    margin: 0 1rem 0 0;
  }

  input {
    display: none;
  }
}

.select-table-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: $global-spacing*3;
  height: 13rem;
  padding: 0 1rem;
  border: 2px solid $light-gray;
  border-radius: 2px;
  cursor: pointer;

  @include mq(sm) {
    min-width: $global-spacing*4.5;
    height: 18rem;
  }

  &:hover,
  &:focus {
    .select-table-item__label:not(.select-table-item__label--top)::before {
      border-color: $body-text;
    }
  }

  &--selected,
  input:checked + & {
    border: 2px solid $primary-color;
  }

  &--selected {
    margin-right: 2.3rem;

    &:after {
      content: "+";
      position: absolute;
      right: -2.6rem;
      font-size: 3rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__title {
    font-size: $medium-heading-size / 2;
    margin: 0.75rem 0 0.2rem;
    line-height: 1.2;
    text-align: center;

    @include mq(sm) {
      font-size: $font-base;
      margin: $global-spacing/2 0 $global-spacing/4;
    }
  }

  &__subtitle {
    font-size: $small-heading-size / 2;
    margin: 0;
    line-height: 1.2;
    text-align: center;

    @include mq(sm) {
      font-size: $font-base-small;
    }
  }

  &__label {
    font-size: $small-heading-size / 2;
    font-family: $heading-font;
    font-weight: $font-weight-normal;
    text-align: center;
    background: $white;
    color: $gray;
    padding: 0 1rem;
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
    margin: 0;
    border: 1px solid $light-gray;

    @include mq(sm) {
      font-size: $font-base-small;
      padding: $global-spacing/8 $global-spacing/2;
    }

    &:not(.select-table-item__label--selected):not(.select-table-item__label--top) {
      // &:before {
      //   @include transition;
      //   content: "";
      //   display: inline-block;
      //   width: 10px;
      //   height: 10px;
      //   background: $white;
      //   border: 1px solid $light-gray;
      //   border-radius: 50%;

      //   input:checked + label & {
      //     display: none;
      //   }
      // }
    }

    &--selected,
    input:checked + label & {
      background: $primary-color;
      border: 1px solid $primary-color;
      color: $white;
    }

    &--top {
      font-size: 0.8rem;
      text-transform: uppercase;
      background: $secondary-color;
      color: $white;
      font-weight: bold;
      top: -2px; // Minus border
      left: -2px;
      right: -2px;
      bottom: auto;
      transform: none;

      @include mq(sm) {
        font-size: 1.2rem;
      }
    }
  }
}

.select-block-items {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  @include mq(sm) {
    margin: $global-spacing 0 0;
  }
}

.select-block-item {
  background-color: $white;
  border: $global-border;
  margin: $global-spacing/2 0 0;
  font-size: 1.4rem;
  width: 100%;
  max-width: 60rem;

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $secondary-color;
    height: 3.2rem;
    color: $white;
    font-weight: bold;
  }

  .select-block-item__title {
    margin-bottom: $global-spacing/4;
  }

  &__intro {
    font-weight: normal;
    margin-bottom: $global-spacing/2;
  }

  &__content {
    padding: $global-spacing / 2;

    @include mq(sm) {
      padding: $global-spacing/2 $global-spacing;
    }

    .list {
      margin: 0 0 1rem;
    }
  }

  &__input {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    background: $body-background;
    padding: 1rem $global-spacing / 2;
    border-top: 1px solid $lightest-gray;

    @include mq(sm) {
      padding: $global-spacing / 3 $global-spacing;
    }

    input[type="radio"] {
      display: none;

      &:checked + .box {
        background: $primary-color;
        border-color: $primary-color;

        &:after {
          border: 2px solid $white;
          border-top: none;
          border-right: none;
          content: "";
          height: 0.3em;
          left: 0.35em;
          opacity: 1;
          position: absolute;
          top: 0.4em;
          transform: rotate(-45deg);
          width: 0.6em;
        }
      }
    }

    .box {
      position: relative;
      width: 2rem;
      height: 2rem;
      background: $white;
      border: 1px solid $gray;
      border-radius: 50%;
      margin: 0 1rem 0 0;
    }
  }
}

.select-block-item .list {
  li {
    padding-left: 3.5rem;
    &:before {
      content: "c";
      color: $secondary-color;
      line-height: 1;
    }
  }
}
