// Booking payments
.payment {
  display: flex;
}

.payment__option {
  position: relative;
  display: inline-flex;
  padding: $global-spacing/3 $global-spacing*0.75;
  height: 5rem;

  img {
    max-height: 2.8rem;
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: $global-border;
  }

  input {
    @include hide-element;
  }

  input:checked ~ {
    label {
      background: rgba($primary-color, 0.1);
      border: 2px solid $primary-color;
    }
  }

  &:not(:last-of-type) {
    margin-right: $global-spacing/4;
  }
}

