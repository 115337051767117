/* =========================================================================
*
* Editor content
* - Content from editor Wordpress
*
* ====================================================================== */

.editor-content {
  img {
    max-width: 100%;
  }

  img[class*="wp-image"] {
    height: auto;
    margin-bottom: $global-spacing;
  }
}