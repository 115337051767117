.reinsurance {
  @include full-background-color();

  @include mq(sm-down) {
    border-bottom: $global-border;
    padding: 0;
  }

  .row {
    padding: $global-spacing/2 0;
  }

  .reinsurance__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: $global-spacing/2 0;
    padding: 0 $global-spacing/2;
    text-align: center;
  }

  .reinsurance__image {
    display: flex;
    align-items: center;
    height: 8rem;
    margin-bottom: $global-spacing/8;
    padding: $global-spacing/2;
  }

  .reinsurance__image img {
    max-height: 6rem;
    max-width: 6rem;
    transition: all 0.2s ease-in-out;
    width: 100%; // Needs to be defined for IE
    // filter: grayscale(100%);
    opacity: 0.6;
  }

  .reinsurance__content {
    max-width: 100%; // Fix for text doesn't wrap in a flex-container in IE
    color: $body-text;
    font-weight: $font-weight-normal;
    line-height: 1.3;
    transition: all 0.2s ease-in-out;
    font-size: $font-base-smallest;

    strong {
      display: inline-block;
      margin-bottom: 0.1rem;
      font-family: $heading-font;
      font-size: $font-base;
      font-weight: $font-weight-bold;
    }
  }

  // hover state
  .reinsurance__item:hover {
    .reinsurance__image img {
      transition: all 0.2s ease-in-out;
      // filter: grayscale(0%);
      opacity: 1;
    }
  }

  // responsive
  @include mq(sm-down) {
    .reinsurance__item {
      flex-direction: row;
      margin: $global-spacing/4 0;
      text-align: left;
    }

    .reinsurance__image {
      flex-shrink: 0;
      padding: 0 $global-spacing/2 0 0;
      width: 8rem;

      img {
        max-height: 5rem;
        max-width: 5rem;
      }
    }
  }

  @include mq(md) {
    .row {
      flex-wrap: nowrap;
      padding: $global-spacing * 1.5 0 $global-spacing/2;
    }

    .reinsurance__item {
      width: percentage(1/4);
    }
  }

  @include mq(lgx) {
    .row {
      padding: $global-spacing 0;
    }

    .reinsurance__image {
      height: $global-spacing * 3;
    }

    .reinsurance__content {
      font-size: $font-base-smallest;
    }
  }
}
