
.flatpickr-calendar {
  .flatpickr-current-month {
    font-size: 1.6rem;
    padding: 10px;


    select.flatpickr-monthDropdown-months {
      min-height: 2px !important;
    }
  }
}


