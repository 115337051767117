.travel-guarantees {
  @include full-background-color();
  position: relative;
  z-index: 1;
  margin-bottom: $global-spacing*2;
  padding: ($global-spacing--big * 2) 0 $global-spacing;
  color: $white;

  // Set background full-width
  @include mq(sm) {
    padding: 0 0 $global-spacing;
    margin-bottom: $global-spacing * 3;

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      background-image: url("https://static.crusj.com/cache/55149c7b39c80031d97381afbfeb381d.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      top: -8vh;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      max-height: 25em;
    }
  }

  // Content section
  &__content {
    background-color: $primary-color;
    position: relative;
    margin-top: 8vh;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $global-spacing;
    border-radius: $global-radius;

    @include mq(sm-down) {
      margin: 0;
    }
  }

  img {
    position: absolute;
    top: -50px; // half of height img
    right: $global-spacing;

    @include mq(sm-down) {
      max-width: 8rem;
    }
  }

  &__title {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
    padding: $global-spacing--small $global-spacing;
    margin-bottom: 0;
  }

  &__list {
    padding: $global-spacing $global-spacing 0;
    margin-bottom: 0;

    li {
      display: flex;
      align-items: baseline;
      padding-bottom: $global-spacing;

      > div {
        padding-left: $global-spacing * 1.5;
      }

      @include mq(sm) {
        &:nth-child(even) {
          padding-left: 0.75rem;
        }

        &:nth-child(odd) {
          padding-right: 0.75rem;
        }
      }

      @include mq(md) {
        &:nth-child(even) {
          padding-left: $global-spacing/2;
        }

        &:nth-child(odd) {
          padding-right: $global-spacing/2;
        }
      }
    }

    span {
      border: 1px solid $white;
      border-radius: 50%;
      width: $global-spacing;
      height: $global-spacing;
      display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
    }

    p {
      font-size: $font-base-small;
      margin: 0;
    }
  }

  a {
    float: right;
    display: flex;
    align-items: center;
    font-size: $font-base;

    &:after {
      content: "d";
      font-family: "crusj-icon";
      line-height: 1;
    }
  }
}
