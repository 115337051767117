.blogs-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: $global-spacing * 0.75;

  @include mq(sm) {
    flex-direction: row;
  }
}

.blog {
  display: flex;

  @include mq(sm) {
    flex-direction: column;
  }

  @include mq(md-only) {
    // Hide 4th item
    &:nth-child(4) {
      display: none;
    }
  }
}

.blog__image {
  position: relative;
  flex-shrink: 0;
  width: 14rem;
  margin: 0 $global-spacing--small 0 0;
  background: $body-background;
  border-radius: $global-radius;
  overflow: hidden;

  @include mq(smx-down) {
    width: 8rem;
  }

  @include mq(sm) {
    width: 100%;
    height: 0;
    padding-top: percentage(9/16);
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.blog__content {
  flex: 1;
  align-self: center;
}

.blog__title {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  font-size: $font-base;
  margin: 0;
  color: $body-text;

  @include mq(smx-down) {
    font-size: $font-base-small;
  }

  @include mq(sm) {
    font-size: $font-base-large;
    margin: 0 0 $global-spacing/4 0;
    line-height: 1.3;
  }
}

.blog__subtitle {
  display: flex;
  margin: 0 0 $global-spacing * 0.25;
  font-size: 1.4rem;
  line-height: 1;
  font-weight: $font-weight-normal;
  color: $dark-gray;

  @include mq(sm) {
    margin: $global-spacing--small 0 $global-spacing--small/2;
  }

  // Icon
  &::before {
    margin-right: $global-spacing/4;
  }
}

.blog__text {
  color: $dark-gray;
  font-size: $font-base-small;

  @include mq(sm-down) {
    @include hide-element();
  }
}


/* =========================================================================
*
* Blog detail
*
* ====================================================================== */

.blog-detail__header {
  .page__title + .blog__subtitle {
    margin-top: -$global-spacing/4;
    margin-bottom: $global-spacing;
  }
}

.blog-detail__container {
  max-width: 74rem;
  margin: 0 auto;

  img {
    max-width: 100%;
    height: auto;
  }

  @include mq(lgx) {
    // Expand items out container
    .size-full,
    .slideshow {
      height: 100%;
      width: calc(100% + #{$global-spacing*2});
      margin-left: -#{$global-spacing};
      margin-right: -#{$global-spacing};
      max-width: none;
    }

    p + img {
      margin-top: $global-spacing*1.5;
      margin-bottom: $global-spacing*1.5;
    }
  }
}