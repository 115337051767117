.location {
	background-color: $body-background;
	min-height: 20em;
	padding: 0;
}

#map {
    height: 20em;
 }

 @include mq(sm-down) {
 	.location {
 		min-height: 15em;
 		margin-bottom: $global-spacing/2;
 	}

 	#map {
 		height: 15em;
 	}
 }
