/* =========================================================================
*
* Footer top
* - Includes newsletter & social links
*
* ====================================================================== */

.footer-top {
  padding: $global-spacing 0;

  h4 {
    margin-bottom: $global-spacing--small;
  }

  @include mq(md) {
    background-color: $primary-color;
    color: $white;

    section {
      padding-bottom: 0; // override default padding
    }
  }

  @include mq(sm-down) {
    @include full-background-color();
    z-index: auto;
  }
}

/* =========================================================================
*
* Footer
*
* ====================================================================== */

footer {
  z-index: 0;

  .footer-inner {
    padding: $global-spacing*1.5 0 $global-spacing;

    @include mq(sm-down) {
      padding-bottom: 0;
    }
  }

  @include mq(lg-down) {
    padding-bottom: $global-spacing * 3; // Needs extra space for `fixed-footer`
  }

  [class^='col']:not(:last-of-type) {
    padding-right: 1rem;

    @include mq(sm-down) {
      margin-bottom: $global-spacing;
      padding-right: 0;
    }
  }

  .accordion--footer {
    .accordion-title {
      font-size: $font-base;
    }


  li {
    line-height: 1.9;
  }

    @include mq(md) {
      margin: 0;
      flex-direction: row;
      justify-content: space-between;

      > li,
      > li:last-child {
        border: none;
        line-height: 1.5;

        &:hover .accordion-title {
          color: inherit;
        }
      }

      .accordion-title {
        padding: 0;
        pointer-events: none;
        font-size: $font-base;
        margin-bottom: $global-spacing/3;

        &::before {
          content: '';
          display: none;
        }
      }

      .accordion-inner {
        position: relative;
        height: auto;
        padding: 0;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  ul {
    display: flex;
    flex-direction: column;
  }

  .accordion-inner a {
    @include transition;
    color: $dark-gray;
    font-size: $font-base-small;

    &:hover {
      color: $primary-color;
    }

    @include mq(sm-down) {
      display: flex;
      justify-content: space-between;
      font-size: $font-base;
      font-weight: $font-weight-light;
      color: $body-text;

      &::after {
        content: 'd';
        font-family: $icon-font;
      }
    }
  }

  .footer-inner > .col-md-4 {
    @include mq(md-only) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    @include mq(sm-down) {
      order: 1;
      margin: $global-spacing*1.5 0 0;
    }
  }

  .help {
    text-align: left;
  }

  .help__content {
    display: flex;
    align-items: center;
    margin: $global-spacing/2 0;
    font-size: $font-base-small;
    font-weight: $font-weight-normal;

    &:before {
      padding-right: $global-spacing/2;
    }

    a {
      color: $body-text;

      &:hover {
        color: $primary-color;
      }
    }
  }

  .footer-logo {
    img {
      width: 12rem;
      margin-bottom: $global-spacing;
    }
  }

  .widget {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }

  .trustpilot-widget {
    max-width: 20rem;

  }
}

.footer__bottom {
  margin-top: $global-spacing/2;

  @include mq(sm) {
    border-top: 1px solid $lightest-gray;
  }

  ul {
    display: flex;

    @include mq(sm) {
      padding: $global-spacing/2 0;
      flex-direction: row;
    }
  }

  li:not(:last-child) {
    padding-right: $global-spacing;

    @include mq(smx-down) {
      padding-right: 0;
    }
  }

  a {
    font-size: $font-base-smallest;
    color: $dark-gray;
  }
}
