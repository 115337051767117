html {
  box-sizing: border-box;
  height: 100%;
  font-size: 10px;
}

html,
body {
  margin: 0;
  padding: 0;
  // overflow-x: hidden;
  width: 100%;
}

body {
  position: relative;
  font-size: $font-base;

  &.has-overlay {
    position: fixed;
  }
}

main {
  overflow: hidden; // nothings inside main extends
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

ul {
  padding: 0;
  margin: 0;

  main & {
    margin: 0 0 $global-spacing;
  }
}

li {
  list-style-type: none;
}

figure {
  margin: 0;
}

// truncate text
.read-more__block {
  position: relative;
  padding-bottom: 0 !important;

  .read-more__container {
    height: 25.6rem;
    margin-bottom: $global-spacing * 1.5;
    overflow: hidden;
    transition: height 0.6s ease-out;
  }

  .read-more__fade {
    display: block;
    position: absolute;
    height: 16rem;
    width: 100%;
    bottom: 0;
    padding-bottom: $global-spacing--small;
    background: $white;
    background: linear-gradient(rgba(255, 255, 255, 0), $white 90%, $white);
    border-bottom: $global-border;
  }

  .read-more__button {
    position: absolute;
    display: inline-block;
    margin-left: 0;
    padding: $global-spacing/4;
    bottom: -2rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: $white;
    border: $global-border;
    border-radius: 0.4rem;
    outline: none;
    cursor: pointer;
  }
}

// Active state
.read-more__block.active {
  .read-more__container {
    margin-bottom: $global-spacing/4;
  }
}
