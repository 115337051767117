.anchor {
	color: $body-text;
	display: block;

	&:after {
		color: $primary-color;
		vertical-align: middle;
		padding-right: $global-spacing/4;
		content: "\64";
		font-size: 2.4em;
		font-family: $icon-font !important;
		speak: none;
		line-height: 1;
	}
}

// Crosslink list

.crosslink-list {
	display: flex;
	flex-wrap: wrap;
	margin-left: -$global-spacing/2;
	margin-right: -$global-spacing/2;

	&__item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin: $global-spacing/4;
		padding: $global-spacing/2 $global-spacing/4;
		background: $white;
		border: $global-border;
		box-shadow: 0px 2px 10px rgba(0,0,0,.05);
		font-family: $heading-font;
		font-size: $font-base;
		color: $primary-color;
		cursor: pointer;
		transition: all ease-in-out 0.25s;

		@include mq(sm) {
			padding: $global-spacing/2;
			width: calc(#{percentage(1/3)} - #{$global-spacing/2});
		}

		// icon
		&:before {
			order: 2;
		}

		// hover state
		&:hover {
			transform: translateX($global-spacing/6);
			box-shadow: inset 0 -1px 0, 0px 2px 10px rgba(0,0,0,.05);

			&:before {
				transform: scale(1.2);
			}
		}
	}
}