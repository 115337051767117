.accordion {
	> li {
		border: $global-border;
		border-bottom: none;

		&:last-child {
			border-bottom: $global-border;
		}

		&:hover > .accordion-title {
			color: $primary-color;
		}
	}

	%accordion-title,
	.accordion-title {
		display: flex;
		align-items: center;
		margin: 0;
		padding: $global-spacing/2 $global-spacing/2;
		color: $body-text;
		font-size: $font-base;
		cursor: pointer;
		transition: all .15s;

		@include mq(sm-down) {
			font-size: $font-base-small;
		}

		// Icon
		&:before {
			transition: all .15s;
			margin-right: $global-spacing/2;
			font-size: 14px; // Needs to be px for IE
		}

		// E.g. price
		span:not(.tab-title) {
			margin-left: auto;
		}
	}

	.accordion-inner {
		padding: 0 $global-spacing/2 $global-spacing/2;
		position: absolute;
		visibility: hidden;
		opacity: 0;
		height: 0;
		transition: opacity .0s, visibility .5s .5s;
		overflow: hidden;

		> *:last-child {
			margin-bottom: 0;
		}

		@include mq(sm-down) {
			font-size: $font-base-small;
		}
	}

	// Active statement
	.active > .accordion-inner {
		position: relative;
		visibility: visible;
		opacity: 1;
		height: auto;
		transition: all 0.5s ease-in-out;
		overflow: auto;
	}

	// Icon
	.active > .accordion-title:before {
		transform: rotate(180deg);
	}

	// Expand accordion when in block content
	.block__content & {
		@include mq(sm) {
			margin-left: -$global-spacing;
			margin-right: -$global-spacing;
		}
	}

	@include mq(sm-down) {
		margin-left: (-(map-get($grid-gutter-width ,'small')));
		margin-right: (-(map-get($grid-gutter-width ,'small')));
	}
}