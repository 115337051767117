.travel-experience {
  position: relative;

  @include mq(lgxx) {
    padding-top: $global-spacing * 2;
    padding-bottom: $global-spacing * 3;
  }

  .row {
    margin: 0 (-$global-spacing/2);

    > * {
      padding: 0 $global-spacing/2;
    }
  }
}

.review {
  @include mq(sm-down) {
    margin: $global-spacing--small 0;
  }

  ul {
    display: flex;
    margin: 0 (-$global-spacing/2);

    @include mq(sm-down) {
      flex-wrap: nowrap;
      overflow-x: auto;
      scroll-snap-type: x proximity;
      margin-bottom: $global-spacing;
      width: 100vw;
    }
  }

  .review__block {
    margin-bottom: $global-spacing;
    padding: 0 $global-spacing/2;

    @include mq(sm-down) {
      scroll-snap-align: start;
      min-width: 36rem;
      margin-bottom: $global-spacing;
    }

    @include mq(smx-down) {
      min-width: 28rem;
    }

    blockquote {
      position: relative;
      margin-bottom: $global-spacing--small;
      padding: $global-spacing * 0.75 $global-spacing * 0.75 $global-spacing *
        0.75 $global-spacing * 2;
      font-weight: $font-weight-normal;
      background-color: $white;
      background: url('../img/quote.svg') 0.5rem 1rem no-repeat;
      background-size: $global-spacing * 1.5;
      box-shadow: $global-shadow-new;
      border-radius: $global-radius;

      &::before,
      &::after {
        content: "";
        position: absolute;
        background: $white;
      }

      // Triangle
      &::before {
        width: $global-spacing/2;
        height: $global-spacing/2;
        bottom: -0.4rem;
        left: $global-spacing * 0.75;
        transform: rotate(45deg);
        filter: drop-shadow(
          0 6px 12px rgba(0, 0, 0, 0.14)
        ); // Same as $global-shadow
      }

      // Overlay for triangle
      &::after {
        height: $global-spacing/2;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

  .review__user {
    display: flex;
    align-items: center;

    p {
      font-weight: $font-weight-normal;
    }

    img {
      width: $global-spacing * 2;
      height: $global-spacing * 2;
      margin-right: $global-spacing/2;
      border-radius: 100%;
      border: 2px solid white;
      box-shadow: $global-shadow-new;
    }
  }

  .review__content {
    h4 {
      margin-bottom: 0;
      font-size: $font-base;
    }

    p {
      margin-bottom: 0;
      font-size: $font-base-small;
      color: $gray;
    }
  }
}
