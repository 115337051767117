input[type='search'],
input[type='text'],
input[type='integer'],
input[type='email'],
select,
textarea {
  background-color: $white;
  border: $global-border;
  border-radius: $global-radius;
  width: 100%;
  padding: 1.1rem $global-spacing/2;
  font-family: $body-font !important;
  font-size: $font-base;
  // min-height: 4rem;
  outline: none;

  &.btn {
    width: auto;
  }
}
textarea {
  height: auto;
}

.form-explanation {
  margin-bottom: $global-spacing/2;
}

// placeholder
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $placeholder-color;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $placeholder-color;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $placeholder-color;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: $placeholder-color;
}

/*=======================================
=            Custom checkbox            =
=======================================*/
.checkbox {
  position: relative;
  display: flex;
  line-height: 1.3em;

  &--filter {
    margin: 0.5em 0;

    &:first-child {
      margin-top: 0;
    }
  }
}

.checkbox label {
  background-color: $white;
  border: 1px solid #ccc;
  cursor: pointer;
  height: 1.3em;
  width: 1.3em;
  left: 0;
  position: absolute;
  top: 0;

  &:hover,
  &:focus {
    border-color: $dark-gray;
  }
}

.checkbox label:after {
  border: 2px solid $white;
  border-top: none;
  border-right: none;
  content: '';
  height: 0.3em;
  left: 0.3em;
  opacity: 0;
  position: absolute;
  top: 0.4em;
  transform: rotate(-45deg);
  width: 0.6em;
}

.checkbox input[type='checkbox'] {
  visibility: hidden;
  width: 1.3em;
  height: 1.3em;
}

.checkbox input[type='checkbox']:checked + label {
  background-color: $primary-color;
  border-color: $primary-color;
}

.checkbox input[type='checkbox']:checked + label:after {
  opacity: 1;
}

.checkbox span {
  margin-left: 1em;
}

// Variant style
.checkbox--round label {
  border-radius: 50%;
}

.checkbox--small label {
  font-size: 0.9em;
}

/*====================================
=            Square checkbox         =
====================================*/

.special-checkbox {
  @include mq(sm-down) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  input[type='checkbox'] {
    position: absolute !important;
    left: -9999px !important;
  }
  label {
    border: $global-border;
    padding: $global-spacing/4 $global-spacing/2;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0.25rem;

    @include mq(sm-down) {
      font-size: 0.8em;
    }
  }

  input:checked + label {
    border-color: $primary-color;
    font-weight: 400;
  }
}

/*====================================
=            Custom radio            =
====================================*/

// Variables - Checkbox
$radio-size: 20px;
$radio-border-size: 1px;
$radio-full-size: 22px; //size + 2x border

%fake-box {
  position: absolute;
  top: 0;
  left: 0;
  height: $radio-size;
  width: $radio-size;
  background: $white;
  border-radius: 50%;
  border: $radio-border-size solid $dark-gray;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 12px;
    width: 12px;
    margin-left: -6px;
    margin-top: -6px;
    background: $primary-color;
    border-radius: 50%;
    visibility: hidden;
  }

  @include mq(sm-down) {
    height: $radio-size * 0.75;
    width: $radio-size * 0.75;

    &::after {
      height: 9px;
      width: 9px;
      margin-left: -5px;
      margin-top: -5px;
    }
  }
}

// Radio
.radio-wrapper-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $global-spacing;

  .radio-wrapper--button,
  .input-label {
    margin: 0 0.25rem 0.25rem 0;
  }

  .radio-wrapper:not(last-of-type) {
    margin-right: $global-spacing/2;
  }

  &.disabled {
    label {
      color: #999;
    }
  }
}

.radio-wrapper {
  display: inline-block;
  position: relative;

  label {
    font-size: $font-base-smallest;
    cursor: pointer;
    display: inline-block;
    height: $radio-full-size;
    line-height: $radio-full-size;
    padding-left: 28px;
    position: relative;
  }

  &.disabled {
    label {
      color: #999;
    }
  }

  input[type='radio'] {
    @include hide-element;

    ~ .fake-box {
      @extend %fake-box;
    }

    &:checked {
      ~ .fake-box {
        &:after {
          visibility: visible;
        }
      }
    }

    &:disabled {
      ~ .radio-text,
      ~ .fake-box {
        opacity: 0.5;
        cursor: default;
      }
    }
  }
}

// Radio button - Button variant
.radio-wrapper--button {
  position: relative;
  display: inline-flex;
  margin-bottom: $global-spacing/4;

  label {
    position: relative;
    padding: $global-spacing/3 $global-spacing/2 $global-spacing/3
      $global-spacing * 1.5;
    background-color: $white;
    border: $global-border;
    cursor: pointer;
    overflow: hidden;
    color: $darkest-gray;

    @include mq(sm-down) {
      padding: $global-spacing/3 $global-spacing/4 $global-spacing/3 3rem;
      font-size: 1.4rem;
    }
  }

  .fake-box {
    @extend %fake-box;
    @include center-y;
    left: $global-spacing/2;
    z-index: 1;
    pointer-events: none;

    @include mq(sm-down) {
      left: $global-spacing/4;
    }
  }

  input:checked {
    ~ label {
      border: 1px solid $primary-color;
      font-weight: $font-weight-normal;
      color: $body-text;
      background: rgba($primary-color, 0.1);
    }

    ~ .fake-box:after {
      visibility: visible;
    }
  }

  input {
    @include hide-element;
  }
}

.radio-wrapper--simple {
  label {
    padding: $global-spacing/2 $global-spacing/1.5;

    @include mq(sm-down) {
      padding: $global-spacing/3 $global-spacing/4;
    }
  }



  &.radio-wrapper--small {
    padding-right: $global-spacing/4;
    padding-bottom: $global-spacing/4;

    label {
      padding: $global-spacing/4 $global-spacing/4;
      font-size: $font-base-small;
    }

  }

  // Icons for radio buttons
  [id^='transporttype'] ~ label {
    display: flex;
    align-items: center;

    &::before {
      font-family: $icon-font;
      line-height: 1.2;
      margin-right: $global-spacing/4;
    }
  }

  // `Eigen vervoer`
  [id$='ev'] ~ label::before {
    content: 'F';
  }

  // `Bus`
  [id$='bu'] ~ label::before {
    content: 'b';
  }

  [id$='vl'] ~ label::before {
    content: 'W';
  }
}

/*====================================
=            Booking page            =
====================================*/
.booking {
  .block {
    margin-bottom: $global-spacing--small;

    @include mq(lgxx) {
      margin-bottom: $global-spacing;
    }

    ~ .block {
      border: $global-border;
    }

    span {
      &.small {
        font-size: 80%;
        color: $gray;
      }
    }
  }
}

.form-row {
  margin-bottom: $global-spacing;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;

  label {
    margin-bottom: 0.4rem;
  }

  input[type='text']:invalid,
  input[type='email']:invalid {
    border: 1px solid #d55c5f;
    color: #d55c5f;
    box-shadow: none; /* Remove default red glow in Firefox */
  }

  &.row {
    // Creeert ruimte tussen input wanneer meerdere in een rij bevinden
    [class*='col']:not(:last-of-type) {
      @include mq(sm) {
        padding-right: $global-spacing/2;
      }
    }
  }

  @include mq(sm-down) {
    .col-xs-12 {
      margin-bottom: 0.5rem;
    }
  }
}

.payment__item {
  margin-bottom: $global-spacing/3;

  .radio-text {
    display: flex;
    align-items: center;
  }

  label {
    font-size: $font-base;
  }

  img {
    &.issuer_logo {
      margin-right: 1rem;
      max-height: 34px;
      max-width: 34px;
    }
  }
}

// Newsletter form
.subscribe-form {
  display: flex;
  margin: 0;
  gap: 0.5rem;

  @include mq(lgxx) {
    overflow: hidden;
  }

  @include mq(sm-down) {
    flex-wrap: wrap;

    .btn {
      width: 100%;
    }
  }

  &__row {
    flex: 1;
    margin: 0;
    padding: 0;

    @include mq(sm-down) {
      flex: 0 0 100%;
    }
  }

  &__input {
    // flex: 1;

    @include mq(sm-down) {
      // flex: 0 0 100%;
      margin: 0 0 0.8rem;
      // height: 4.8rem;
    }
  }

  &__submit {
    box-shadow: none;
  }
}

.subscribe__subtitle {
  font-size: 1.4rem;

  @include mq(sm) {
    display: none;
  }
}


