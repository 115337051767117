// Stepper
.stepper {
  width: 100%;
  background-color: $body-background;
  border: $global-border;

  @include mq(sm-down) {
    @include expand-container;
    margin-top: -$global-spacing;
    width: 100vw;
    border: 0;
    box-shadow: $global-shadow;
  }
}

.stepper-list {
  margin: 0;
  display: flex;
}

.stepper-item {
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  min-height: $global-spacing*1.25;
  background: $body-background;
  color: $gray;
  font-weight: $font-weight-normal;

  &:not(:last-child):not(.stepper-item--active) {
    border-right: 1px solid $lightest-gray;
  }

  a {
    display: flex;
    align-items: baseline;
    color: inherit;
    font-weight: inherit;
  }

  // Text
  span {
    margin-left: $global-spacing/6;

    // Hide text on smaller screen
    @include mq(sm-down) {
      display: none;
      font-size: $font-base-small;
    }
  }
}

// Placeholder active(d) item
%stepper-actived {
  color: $white;
  background: $secondary-color;
}

// Done item - stepper
.stepper-item--done {
  @extend %stepper-actived;
  border-right: 1px solid $white;
  cursor: pointer;

  &:hover,
  &:focus {
    background: $secondary-color-hover;
  }
}

// Active item - stepper
.stepper-item--active {
  @extend %stepper-actived;
  position: relative;
  font-weight: $font-weight-bold;

  // Arrow
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: -5px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent $secondary-color;
  }

  span {
    display: block;
  }
}