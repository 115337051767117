.cards {
  @include full-background-color();
  padding: $global-spacing 0 $global-spacing--big;

  + .cards {
    padding-top: 0;
  }

  @include mq(sm) {
    padding: $global-spacing * 2 0;
  }
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: $global-spacing/2;
  background-color: $white;
  border-radius: $global-radius*2;
  box-shadow: $global-shadow;
  transition: $global-transition;

  > * {
    flex: 1 0 auto;
  }

  p {
    font-size: $font-base-small;
  }

  .list li::before {
    content: "c"; // check icon
  }

  .card__image-wrapper {
    position: relative;
    flex-grow: 0;
    overflow: hidden;
    border-radius: $global-radius;
  }

  .card__image {
    position: relative;
    padding-top: percentage(9/16);

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      font-family: "object-fit:cover";
    }
  }

  .card__content {
    padding: $global-spacing * 0.75 $global-spacing/2 $global-spacing/3;
    font-size: $font-base-small;
    color: $body-text;
    font-weight: $font-weight-light;
    flex: 1 1 auto;

    .card__content--inner {
      position: relative;
    }

    @include mq(sm-down) {
      font-size: $font-base-smallest;
    }
  }

  .card__title {
    margin-bottom: $global-spacing/4;
    font-size: 2.2rem;
    font-weight: 500;

    @include mq(sm-down) {
      font-size: $font-base;
    }
  }

  .card__subtitle {
    margin-bottom: $global-spacing/2;
    color: $dark-gray;
    font-weight: $font-weight-normal;
    line-height: 1.2;

    @include mq(sm-down) {
      font-size: $font-base-smallest;
    }
  }

  .card__bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
  }

  .card__price {
    display: flex;
    align-items: baseline;
    margin-right: $global-spacing/2;
    margin-bottom: 0;
    color: $primary-color;

    // Highlight price
    span {
      padding-left: $global-spacing/4;
      font-size: $medium-heading-size;
      font-weight: $font-weight-bold;
      letter-spacing: -0.5px;
    }
  }

  .card__days {
    font-size: 1.3rem;
  }

  .card__tooltip {
    margin-right: $global-spacing/4;
    color: $gray;

    // Icon
    &::before {
      position: relative;
      top: 0.2rem;
      font-size: 20px; // Needs to be in px for IE 11
    }
  }

  .arrow {
    display: flex;
    transition: $global-transition;
    color: $primary-color;
    margin-left: auto;
  }

  &:hover,
  &:focus {
    box-shadow: $global-shadow--dark;

    .arrow {
      transform: translateX(-$global-spacing/8);
    }
  }
}

/* =========================================================================
*
* Card equal height
* - Used as card homepage
*
* ====================================================================== */

.card--equal-height {
  height: calc(100% - #{$global-spacing/2});

  .card__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

/* =========================================================================
*
* Card simple
* - Used as theme card
*
* ====================================================================== */

.card--simple {
  height: calc(100% - #{$global-spacing/2});

  .card__content {
    padding: $global-spacing/2;
  }

  .card__content--inner {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .card__title {
    margin-bottom: 0;
    padding-right: $global-spacing/4;
    color: $body-text;
    transition: $global-transition;
  }

  &:hover,
  &:focus {
    .card__title {
      color: $primary-color;
    }
  }
}

/* =========================================================================
*
* Card overlay
* - Variant as theme card
*
* ====================================================================== */
.card--overlay {
  .card__content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .card__title {
    margin-bottom: 0;
    padding-right: $global-spacing/4;
    color: $white;
  }

  .card__image::after {
    content: '';
    display: block;
    background: rgba(0,0,0,.4);
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: $global-transition;
  }

  &:hover,
  &:focus {
    .card__title {
      color: $white;
    }

    .card__image::after {
      opacity: 0.5;
    }
  }

}


/* =========================================================================
*
* Card detailed
* - Horizontal view of card (used in overview)
*
* ====================================================================== */

.card--detailed {
  .card__image {
    padding-top: percentage(2/3);
  }

  @include mq(sm-down) {
    @include expand-container;
  }

  @include mq(md) {
    flex-direction: row;

    .card__image {
      height: 100%;
    }

    .card__image-wrapper {
      width: 28rem;
      max-width: 28rem;
      margin-right: $global-spacing/4;
    }
  }

  @include mq(lgx) {
    .card__image-wrapper {
      width: 32rem;
      max-width: 32rem;
    }
  }

  .card__image-container {
    display: flex;
    height: 100%;
    min-width: 100%;
    margin: 0 (-$global-spacing/6);
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      flex: 0 0 100%;
      max-width: 100%;
      max-height: 50vh;
      border-width: 0 $global-spacing/6;
      border-style: solid;
      border-color: transparent;
      scroll-snap-align: start;

      @include mq(lg) {
        &:not(:first-child) {
          display: none;
        }
      }

      @include mq(md-only) {
        flex: 0 0 85%;
				max-width: 85%;
				border-width: 0 $global-spacing/10;
      }

      @include mq(sm-down) {
        flex: 0 0 60%;
        max-width: 60%;
      }

      @include mq(smx-down) {
        flex: 0 0 85%;
        max-width: 85%;
      }
    }
  }
}
