/* =========================================================================
*
* Sticky nav
*
* ====================================================================== */

.sticky-booking {
	position: sticky;
	top: 0;
	background: white;
	box-shadow: $global-shadow--dark;
	padding: $global-spacing/2 0;

	&__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0;
	}

	.price {
		font-weight: $font-weight-medium;

    .price-value {
      > span {
        font-size: 2.5rem;
      }
    }

    &.discounted {
      .price-value {
        background-color: $secondary-color;
        color: $white;
        padding: 0.5rem 1rem;
        border-radius: 3px;
      }
    }

    .meta {
      > span {
        display: inline-block;
        padding: 0 1rem;
        font-size: 1.4rem;
        border-left: 1px solid $lightest-gray;

        &:first-child {
          padding-left: 0;
          border-left: none;
        }
      }
    }

    .vertrekgarantie {
      background-color: $primary-color;
      color: $white;
      padding: .5rem 1rem;
      text-align: center;
      margin-top: .5rem;
      border-radius: 3px;
    }
	}


}

.sticky-wrapper {
	@include mq(lg) {
		display: none;
	}
}
