.results {
  .results__intro {
    padding-bottom: $global-spacing * 0.75;

    @include mq(sm-down) {
      padding-bottom: $global-spacing;
    }

    > *:last-child {
      margin: 0;
    }
  }

  .results__content {
    @include full-background-color($body-background);
    padding: $global-spacing 0;

    @include mq(sm-down) {
      padding: $global-spacing--small 0;
    }
  }

  .results__head {
    padding-bottom: $global-spacing--small;
    margin-bottom: $global-spacing;
    border-bottom: $global-border;

    // @include mq(sm-down) {
    //   display: none;
    // }
  }

  .results__title {
    font-size: $font-base-small;
    margin: 0;

    @include mq(sm-down) {
      font-size: $font-base-smallest;
    }
  }

  .holidays_counter {
    margin-top: $global-spacing;
  }

  .results__display {
    display: flex;
    margin-left: auto;

    @include mq(sm-down) {
      justify-content: space-between;
      width: 100%;
    }
  }

  .results__sort,
  .results__view {
    display: flex;
    align-items: center;

    p {
      margin: 0 1em 0 0;
    }
  }

  .results__sort {
    select {
      width: 5.3em;
      @include mq(sm) {
        width: auto;
      }
    }
  }

  .results__view {
    a {
      cursor: pointer;
    }

    p {
      @include mq(sm-down) {
        display: none;
      }
    }
  }

  .results__icon {
    background-color: white;
    padding: 0.1em 0.3em;
    border: $global-border;
    margin: 0;
    display: inline-block;
    font-size: 1.25em;
    vertical-align: sub;

    &:first-of-type {
      border-right: none;
    }

    &:last-of-type {
      border-left: none;
    }

    &.active {
      background-color: $primary-color;
      color: white;
    }
  }

  // Price table
  .results__grid {
    display: block;
    min-width: 70%;

    &--single {
      display: block;
      padding: $global-spacing 0;
    }

    .highlighted {
      border: 1px solid $primary-color;
    }
  }
}

.availability {
  &__full:before {
    color: #e13b23;
  }

  &__almost-full:before {
    color: #fc9313;
  }

  &__available:before {
    color: #54d264;
  }

  &__going {
    color: $primary-color;
  }
}


.results {

  &__list {


    /* Alles hieronder is voor de listview op de reizen overview pagina */



    &__item {
      border: 1px solid $gray;
      // font-size: 0.975rem;
      position: relative;
      background-color: white;
      padding: 0 1em;
      margin-bottom: 0.5em;

      @include mq(md-down) {
        display: flex;
      }

      @include mq(sm-down) {
        display: none;
        margin-bottom: 1em;
        padding: 0.25em 0.75em;
        transition: all 0.25s;
        overflow: hidden;

        &.active {
          // max-height: 36rem;
          max-height: 100%;  // use all height!
        }

        .only-mobile {
          display: inline-block !important;
        }
      }

      &.active {
        > .results__list__ages {
          border-top: 1px dashed $dark-gray;
          // margin-top: 0.5em;
          padding: 1em 0 1em;
          height: 100%;
        }

        > .results__list__more {
          a {
            transform: rotate(180deg);
          }
        }
      }

      p {
        margin: 1.1em 0;
        @include mq(sm-down) {
          margin: 0.1em 0;
        }

      }

    }

    &__discount {
      .results__list__price {
        > p:last-child {
          background-color: $secondary-color;
          color: $white;
          font-weight: bold;
          padding: .25rem .5rem;
          border-radius: 3px;
        }
      }
    }

    &__is-garanteed {
      .results__list__date {
        > p:last-child {
          background-color: $primary-color;
          color: $white;
          font-weight: bold;
          padding: .25rem .5rem;
          border-radius: 3px;
        }
      }
    }

    &__column {

      > p {
        @include mq(sm-down) {
          display: inline-block;
          float: left;
        }

        &:first-child {
          min-width: 40vw;
          @include mq(md) {
            display: none;
          }
        }
      }

    }

    &__title {
      text-align: left;
      font-weight: bold;

      @include mq(sm-down) {
        // order: -2;
        color: $primary-color;
        font-family: $heading-font;
        padding: 0.2em 0;
      }
    }

    &__gender {
      display: flex;
      cursor: pointer;

    }

    &__age {
      white-space: nowrap;
    }

    &__ages {
      height: 0;
      overflow: hidden;

      h5 {
        display: inline-block;
      }

      // direct span after h5
      h5 + span {
        display: inline-block;
        margin-bottom: $global-spacing/2;
        color: $dark-gray;
      }

      @include mq(md) {
        // margin-top: 0.25em;
        overflow: hidden;
        order: 10;
        padding: 0;

        &--inner {
          padding-left: 1em;
        }

        h5 {
          display: block;
        }
      }


      .availability__going {
        display: inline-flex;
        position: relative;
        margin: 1.2em 0.5em 0.5em 0;
        color: $body-text;
        font-size: 0.95em;
        @include mq(md) {
          margin: 1.35em 0.3em 0 0.3em;

          .btn {
            margin: 0.5em 0 1.5em;
          }
        }

        &:before {
          position: absolute;
          bottom: 1.2em;
          color: $primary-color;
          font-size: 1.1em;
        }
      }
    }

    &__subtitle {
        font-size: 0.9rem;
        color: $dark-gray;
        text-transform: uppercase;
        font-weight: 400;
    }

    &__more {
      display: none;
      @include mq(sm-down) {
        display: flex;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 3.5em;
        background-color: rgba(233, 233, 233, 0.55);
        top: 0;

        a {
          display: block;
          font-size: 1.5rem;
          padding: 10rem 1.5rem;
          transition: all 0.25s;
        }
      }
    }

    &__price {
      white-space: nowrap;

      @include mq(md) {
        order: 2;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        > p {
          // margin: 0;
          // line-height: 1.3;
        }
      }

      @include mq(md) {
        margin: 0 .5rem;
      }
    }

    &__price-item {
      // oude prijs in overzicht
        font-size: 0.9rem;
        color: $gray;
        text-decoration: line-through;
        line-height: 1.3;
        flex: 0 0 100%;
    }

    &__btn {
      @include mq(md) {
        order: 3
      }
      @include mq(sm-down) {
        padding: 0.5em 0;
        display: none;
      }
    }
  }
}

