.videoWrapper {
	position: relative;
	width: 100%;
	height: 0;
	background-color: black;
	margin-bottom: $global-spacing;
	border-top: 3px solid $primary-color;

	@include mq(lgx) {
		margin-bottom: 0;
	}

	&43 {
		padding-top: 75%;
	}
	&169 {
		padding-top: 56%;
	}

	&.videoWrapperActive {
		&:before {
			display: none;
		}
	}
}
.videoIframe {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: transparent;
}
.videoPoster {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	cursor: pointer;
	border: 0;
	outline: none;
	background-position: 50% 50%;
	background-size: 100% 100%;
	background-size: cover;
	text-indent: -999em;
	overflow: hidden;
	opacity: 1;
	transition: opacity 800ms, height 0s;
	transition-delay: 0s, 0s;

	&:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 8rem;
		height: 8rem;
		transform: translate3d(-50%, -50%, 0);
		background-color: rgba($primary-color, 0.9);
		border-radius: 100%;
		transition: all 300ms;
		z-index: 2;
	}

	&:after {
		content: 'b';
		font-family: 'crusj-icon';
		position: absolute;
		top: 50%;
		left: 50%;
		color: $white;
		background-image: url('../img/play.svg');
		width: 34px;
		height: 44px;
		transform: translate3d(-38%, -50%, 0);
		transition: all 300ms;
		z-index: 2;
	}
	&:hover,
	&:focus {
		opacity: 1;
		&:before {
			background-color: $primary-color;
			transform: translate3d(-50%, -50%, 0) scale(1.1);
		}
	}

	.videoWrapperActive & {
		opacity: 0;
		height: 0;
		transition-delay: 0s, 800ms;
	}
}

/* =========================================================================
*
* Video variant
*
* ====================================================================== */

.video {
	&.video-variant {
		@include full-background-color($white);
		padding: 0;
		margin-bottom: $global-spacing;
		@include mq(lgx) {
			margin-bottom: $global-spacing * 3.5;
		}
	}
}
