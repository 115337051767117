.input-wrapper {
  + .input-wrapper {
    margin-top: $global-spacing * 0.75;
  }

  padding-right: 3rem;

  &--success {
    position: relative;
    color: $secondary-color;

    &:after {
      vertical-align: middle;
      content: "c";
      font-size: $small-heading-size;
      font-family: "crusj-icon" !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-variant: normal !important;
      text-transform: none !important;
      position: absolute;
      right: 0;
    }
    input {
      border-color: $secondary-color;
    }
  }

  &--error {
    color: $error-color;

    input {
      border-color: $error-color;
      background: transparentize($error-color, 0.95);
    }
  }
}

.input-label {
  display: block;
  line-height: $heading-line-height;
  font-size: $font-base-small;
  font-weight: $font-weight-normal;
  color: $black;
  margin-bottom: $global-spacing * 0.25;
}

.input-error {
  display: block;
  color: $error-color;
  font-size: 1.2rem;
}
