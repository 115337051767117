.slideshow {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	margin-bottom: $global-spacing*2;

	@include mq(sm-down) {
		margin-bottom: $global-spacing;
		justify-content: space-between;
	}

	> a {
		position: relative;
		width: percentage(1/3);
		overflow: hidden;
		backface-visibility: hidden;

		@include mq(sm-down) {
			width: calc(50% - 1px);
		}

		&:hover img {
			transform: scale(1.04);
		}

		&:nth-child(n+3) {
			&:after {
				content: attr(data-content);
				position: absolute;
				right: $global-spacing/2;
				bottom: $global-spacing/2;
				padding: $global-spacing/10 $global-spacing/4;
				background: $white;
				color: $darkest-gray;
				font-size: $font-base-smallest;
				font-weight: $font-weight-bold;
				border: $global-border;
				border-radius: $global-radius;
			}
		}

		&:nth-child(1) {
			@include mq(sm-down) {
				width: 100%;
				margin-bottom: 2px;
			}
		}

		&:nth-child(n+4) {
			display: none;
		}
	}

	* img {
		width: 100%;
		height: 12rem;
		margin-bottom: 0;
		transition: $global-transition;

		@include mq(sm) {
			height: 18rem;
		}

		@include mq(lgx) {
			height: 28rem;
		}

		display: block;
    object-fit: cover;
    font-family: 'object-fit:cover';
	}

	&__large {
		* img {
			display: block;
		}
	}

	&--small > a {
		width: 50%;

		img {
			height: 15rem
		}

		&:first-child {
			width: 100%;

			img {
				height: 20rem;
			}
		}
	}
}

.siema {
	width: 100%;

	* div {
		padding: $global-spacing/8;
	}
}

.prev, .next {
	color: $primary-color;
	position: absolute;
	top: 6em;
	background: $body-background;
	border: $global-border;
	width: $global-spacing*1.25;
	height: $global-spacing*1.25;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&:before {
		font-size: 1.4em;
		line-height: 1.4em;
	}
}

.prev {
	left: -1rem;
	transform: rotate(90deg);
}

.next {
	right: -1rem;
	transform: rotate(-90deg);
}