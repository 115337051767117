// Global unordered list
%ul,
.ul {
	padding-left: $global-spacing;

	li {
		position: relative;
		margin-bottom: $global-spacing/4;

		&::before {
			content: "\2022";
			position: absolute;
			left: -.9em;
		}
	}
}

.list {
	margin-bottom: $global-spacing;

	li {
		position: relative;
		padding-left: $global-spacing*1.5;

		&:before {
			color: $primary-color;
			vertical-align: middle;
			padding-right: $global-spacing/4;
			content: 'K';
			font-size: $small-heading-size;
			font-family: 'crusj-icon' !important;
			font-style: normal !important;
			font-weight: normal !important;
			font-variant: normal !important;
			text-transform: none !important;
			line-height: 1.3;
			position: absolute;
			left: 0;
		}

		&:not(:last-of-type) {
			margin-bottom: $global-spacing/4;
		}

		@include mq(md-down) {
			padding-left: $global-spacing;

			&::before {
				font-size: 2rem;
				top: 0.2rem;
			}

			&:not(:last-of-type) {
				margin-bottom: $global-spacing/8;
			}
		}
	}

	&--small {
		margin: 0 0 $global-spacing/4;

		li {
			padding-left: $global-spacing;

			@include mq(md-down) {
				padding-left: $global-spacing*0.8;

				&::before {
					top: 0;
				}
			}
		}
	}

	&__head {
		font-size: $font-base-small;
		color: $dark-gray;
		text-transform: uppercase;
		font-weight: $font-weight-normal;
		padding: 0 $global-spacing/2;

		@include mq(md) {
			padding: 0 $global-spacing/2 $global-spacing/4;
		}

		@include mq(md-down) {
			display: none;
		}

		p {
			margin: 0 0 $global-spacing/4;
			font-size: $font-base-smallest;
		}
	}

	/* Alles hieronder is voor de listview op de reizen overview pagina */

	&__item {
		border: $global-border;
		border-radius: $global-radius*2;
		font-size: $font-base-small;
		position: relative;
		background-color: white;
		padding: $global-spacing/8 $global-spacing/2;
		margin-bottom: $global-spacing/4;
		display: none;

		@include mq(md) {
			display: flex;
			padding: $global-spacing/4 $global-spacing/2;
		}

		&.active {
			> .list__ages {
				max-height: 22.4rem;
				padding: $global-spacing/2 0 0;

				@include mq(md) {
					margin-top: $global-spacing/4;
					padding: $global-spacing/2 0;
					border-top: $global-border;
				}

			}
		}

		p {
			margin: 1.1rem 0;
		}
	}

	&__column {
		> p:first-child {
			display: none;
		}
	}

	&__title {
		text-align: left;
	}

  &__date {

  }

  &__is-garanteed {
    > p:last-child {
      background-color: $primary-color;
      color: $white;
      font-weight: bold;
      padding: .5rem 1rem;
      border-radius: 3px;
    }
  }

	&__gender {
		display: flex;
	}

	&__ages {
		max-height: 0;
		overflow: hidden;
		transition: 0.25s;

		h5 {
			display: inline-block;
		}

		// direct span after h5
		h5 + span {
			display: inline-block;
			margin-bottom: $global-spacing/2;
			color: $dark-gray;
		}

		@include mq(md) {
			order: 10;
			padding: 0;

			&--inner {
				padding-left: $global-spacing/2;
			}
		}

		.availability__going {
			display: inline-flex;
			position: relative;
			margin: $global-spacing/2 $global-spacing/4 $global-spacing/4 0;
			color: $body-text;
			font-size: $font-base-smallest;

			@include mq(md) {
				margin: 1.35rem 0.3rem 0 0.3rem;

				.btn {
					margin: $global-spacing/4 0 $global-spacing*0.75;
				}
			}

			&:before {
				position: absolute;
				bottom: 2rem;
				color: $primary-color;
				font-size: $font-base;
			}
		}
	}

	&__subtitle {
		font-size: $font-base-small;
		color: $dark-gray;
		text-transform: uppercase;
		font-weight: 400;
	}

	&__more {
		display: none;
	}

	&__price.list__column {
		order: 2;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		> p {
			margin: 0;
			line-height: 1.3;
		}
	}

	&__price-item {
		// oude prijs in overzicht
		font-size: 1.4rem;
		color: $gray;
		text-decoration: line-through;
		line-height: 1.3;
		flex: 0 0 100%;
	}

	&__btn {
		order: 3;
	}
}
