.basic-page {
  padding: $global-spacing * 1.5 0;

  @include mq(sm-down) {
    padding: $global-spacing 0;
  }

  &--alt {
    padding: $global-spacing * 1.5 0 0;
  }
}

// Sections
.section {
  margin-bottom: $section-margin;

  > .section {
    margin-bottom: 0;
  }

  &__title {
    text-align: left;
    width: 100%;

    @include mq(sm) {
      display: flex;
      justify-content: space-between;
    }
  }
}

// TODO: Remove slowly global padding for sections, use class `.section` instead
section {
  padding-bottom: $global-spacing;

  > section {
    padding-bottom: 0;
  }
}

// Component
.component {
  margin-bottom: $comp-margin;
  padding-bottom: 0;
}

// more space needed sections
.cards,
.travel-guarentees {
  padding-bottom: $global-spacing--big * 2;
}

.alternative-bg {
  @include full-background-color($body-background);
  &.basic-page {
    z-index: initial;
  }
}

.img {
  &--full-width {
    width: 100%;
    object-fit: cover;
    margin: 2em 0;
  }

  &--shadow {
    border: 7.5px solid white;
    box-shadow: 1px 2px 40px 2px rgba(0, 0, 0, 0.08);
  }
}

// Fixed footer
%fixed-footer,
.fixed-footer {
  display: none; // Hide on default

  @include mq(lg-down) {
    position: fixed;
    display: block;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 100;
    height: $fixed-footer-height;
  }
}
