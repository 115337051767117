.crusj-cookie-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 100;
  background-color: $white;
  border: $global-border;
  box-shadow: $global-shadow-new;

  @include mq(sm) {
    right: 2%;
    bottom: $global-spacing * 2;
    left: 2%;
    width: 96%;
  }

  &__container {
    margin: ($global-spacing * 0.75) ($global-spacing / 2);

    @include mq(sm) {
      margin: $global-spacing $global-spacing ($global-spacing * 0.75);
    }
  }

  &__content {
    @include mq(sm) {
      display: flex;
      max-width: 100rem;
    }
  }

  &__info {
    font-size: $font-base-smallest;

    @include mq(sm) {
      font-size: $font-base-small;
      border-right: $global-border;
      padding-right: $global-spacing;
      margin-right: $global-spacing;
    }
  }

  &__control {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;

    .crusj-cookie-bar__link {
      margin-top: $global-spacing / 2;
      font-size: $font-base-small;
    }
  }


}
