/* =========================================================================
*
* Tabs
* - Responsive accordion variant
*
* ====================================================================== */

@include mq(lgxx) {
  .tabs.accordion {
    padding: 0 $global-spacing/2;
  }
}

@include mq(lgx) {
  .component-tab {
    margin-bottom: $global-spacing*2;
  }

  .tabs.accordion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background: $white;
    margin: 0 -$global-spacing/2;
    box-shadow: $global-shadow-new;

    .tab-item > .accordion-title {
      padding: $global-spacing*0.75 $global-spacing/2;
    }

    .tab-item,
    .tab-item:last-child {
      border: 0;
    }

    > .active .tab-inner {
      position: absolute;
      top: calc(100% + (#{$global-spacing}*1.5)); // Acts like a margin top due
      left: 0;
      width: 100%;
    }

    .tab-item > .accordion-title::before {
      content: none; // Hide arrow when it's a tab
    }

    .tab-item--btn {
      .accordion-title {
        padding: $global-spacing/4 $global-spacing/3;
        margin-right: $global-spacing/3;
        background: $primary-color;
        border-color: $primary-color;
        border-radius: $global-radius;
        box-shadow: 0 3px 0 0 $primary-color-hover;

        &:hover {
          background-color: $primary-color-hover;
          border-color: $primary-color-hover;
        }
      }

      .tab-title {
        color: $white;
      }
    }
  }

  .active  {
    .tab-item > .accordion-title {
      box-shadow: 0 -2px inset $primary-color;
    }
    .tab-title {
      color: $primary-color;
    }
  }
}

@include mq(md-only) {
  // Add border for second last item in accordion
  // due removing 'booking' button on smaller screen
  .tabs .tab-item:nth-last-child(2) {
      border-bottom: $global-border;
    }
}


.tab-title {
  display: block;

  margin: 0;
  font-weight: $font-weight-medium;
  font-size: $font-base-small;

  // Icon
  &::before {
    padding-right: $global-spacing/6;
    vertical-align: baseline;
  }
}
