.tooltip {
  position: relative;
  cursor: pointer;
  float: right;

  	&:before {
		content: attr(data-tooltip);
		position: absolute;
		min-width: 8em;
		bottom: 2.2em;
		font-size: 0.9em;
		padding: 0.25em 0.3em 0.5em;
		color: white;
		background: rgba(0, 0, 0, 0.75);
		border-radius: $global-radius;
		transition: opacity 0.2s ease-out;
		z-index: 99;
		text-align: left;
		opacity: 0;
		right: -0.25em;
	}

	&:after {
		content: '';
		position: absolute;
		border: 6px solid rgba(0, 0, 0, 0.75);
		border-color: rgba(0, 0, 0, 0.75) transparent transparent;
		bottom: 1.17em;
		left: 0.125em;
		opacity: 0;
	}

	&:hover:before,
	&:hover:after {
		display: inline-block;
		opacity: 1;
	}
}

// Hieronder een andere tooltip -- werkt iets makkelijker voor m'n gevoel.

.tooltips {
	position: relative;
	text-transform: none;

	&__inner {
		display: none;
		position: absolute;
		font-size: $font-base-smallest;
		line-height: 1.4;
		width: auto;
		min-width: 20rem;
		bottom: $global-spacing*1.25;
		left: 50%;
		transform: translateX(-50%);
		background-color: black;
		z-index: 999;
		color: $white;
		padding: $global-spacing/4 $global-spacing/3;
		text-align: center;
		border-radius: $global-radius;

		&:after {
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-top: 10px solid black;
			position: absolute;
			bottom: -10px;
			content: "";
			left: 50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
		}
	}

	.wrap {
		white-space: normal;
	}

	&:hover {
		.tooltips__inner {
			display: block;
		}
	}

	> span[data-icon] {
			color: $primary-color;
    	margin-left: 0.2em;
	}
}

.tooltips--bottom {
	.tooltips__inner {
		top: $global-spacing*1.25;
		bottom: auto;

		&:after {
			border-top: 10px solid transparent;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid black;
			top: -18px;
			bottom: auto;
		}
	}
}
