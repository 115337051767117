/*=============================================
=            Destination overview            =
=============================================*/

.destination-overview {
	position: relative;
	margin-top: $global-spacing--big;
	padding-top: $global-spacing--big;
	border-top: $global-border;

	&:before {
		content: '';
		position: absolute;
		width: 100vw;
		height: 100vw;
		left: -25%;
		margin-top: -$global-spacing;
		background: url('../img/worldmap.svg');
		background-size: 90%;
		background-repeat: no-repeat;
		opacity: 0.15;
		z-index: -1; // stay below footer
	}

	> section {
		margin: $global-spacing 0;

		> h3 {
			color: $body-text;
		}
	}

}

/*=============================================
=            Single destination               =
=============================================*/

.tour-guides {

	// Single reisleider
	.tour-guide {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 0 $global-spacing/2;

		img {
			transition: 0.3s all;
			margin-bottom: $global-spacing/4;
			width: 7rem;
			height: 7rem;
			border-radius: 50%;
			border: 6px solid white;
			box-shadow: $global-shadow;

			@include mq(lgx) {
				width: 10rem;
				height: 10rem;
			}
		}

		.tour-guide__name {
			color: $primary-color;
			font-weight: $font-weight-medium;
		}
	}
}